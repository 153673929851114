import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Spinner,
  FormFeedback,
} from "reactstrap";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../axios";

const AddEmployee = () => {
  const navigate = useNavigate();
  const genders = ["Male", "Female", "Other"];
  const [submitting, setSubmitting] = useState(false);

  const [employeeData, setEmployeeData] = useState({
    name: "",
    dateOfBirth: "",
    gender: "male",
    phone_Number: "",
    email_Address: "",
    emergency_contact: "",
    ImageFile: "",
    bank_acc_number: "",
    salary: 0.0,
  });

  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setEmployeeData({
      ...employeeData,
      [fieldName]: newValue,
    });
  };

  const handleFileChange = (e) => {
    setEmployeeData({
      ...employeeData,
      ImageFile: e.target.files[0],
    });
  };

  const [regData, setRegData] = useState({
    username: "",
    email: "",
    password: "",
  });

  const handleRegChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setRegData({
      ...regData,
      [fieldName]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const isValid = validateForm();

    if (!isValid) {
      return;
    }

    setSubmitting(true);
    const loginFormData = new FormData();
    loginFormData.append("username", regData.username);
    loginFormData.append("email", regData.email);
    loginFormData.append("password", regData.password);

    try {
      const respone = await axiosInstance.post(
        "/api/Authentication/register?role=Employee",
        loginFormData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (respone.status === 200) {
        const uid = respone.data.message;
        const formDataToSend = new FormData();
        //form data object ko key value pair
        //key should match key in server.

        console.log("DATE: " + employeeData.dateOfBirth);
        formDataToSend.append("id", 0);
        formDataToSend.append("uid", uid);
        formDataToSend.append("name", employeeData.name);
        formDataToSend.append("dateOfBirth", employeeData.dateOfBirth);
        formDataToSend.append("gender", employeeData.gender);
        formDataToSend.append("phone_Number", employeeData.phone_Number);
        formDataToSend.append("email_Address", regData.email);
        formDataToSend.append(
          "emergency_Contact",
          employeeData.emergency_contact
        );
        formDataToSend.append("imageFile", employeeData.ImageFile);
        formDataToSend.append(
          "bankAccountNumber",
          employeeData.bank_acc_number
        );
        formDataToSend.append("salary", employeeData.salary);

        try {
          console.log(formDataToSend.data);
          const response = await axiosInstance.post(
            `/api/Employee`,
            formDataToSend
          );
          if (response.status === 201) {
            // Success
            alert("Data updated successfully!");
            navigate(-1);
          } else {
            // Display an error message
            alert("Failed to update data. Please try again.");
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        // Display an error message
        alert("Registration failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setSubmitting(false);
    }
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const validationErrors = {};

    if (!regData.username) {
      validationErrors.username = "Username is required";
    }
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const validEmail = emailRegex.test(regData.email);
    if (!regData.email) {
      validationErrors.email = "Email is required";
    } else if (!validEmail) {
      validationErrors.email = "Email format not correct";
    }
    const passwordRegex = /^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$/;
    const validPassword = passwordRegex.test(regData.password);
    if (!regData.password) {
      validationErrors.password = "Password cannot be empty!";
    } else if (!validPassword) {
      validationErrors.password =
        "Password must contain at least one Uppercase, one symbol and one number.";
    }
    if (!employeeData.name) {
      validationErrors.name = "Name of the employee is required!";
    }
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return false;
    }
    return true;
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        <i className="bi bi-bell me-2"> </i>
        Employee Form
      </CardTitle>
      <CardBody>
        {/* username */}

        <FormGroup>
          <Label for="username">Username</Label>
          <Input
            id="username"
            name="username"
            placeholder="Enter  username"
            type="text"
            value={regData.username}
            onChange={(n) => handleRegChange(n, "username")}
            invalid={!!errors.username} // Apply invalid state based on validation
          />
          <FormFeedback>{errors.username}</FormFeedback>{" "}
          {/* Display error message */}
        </FormGroup>

        {/* email */}

        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            id="email"
            name="email"
            placeholder="Enter email"
            type="text"
            value={regData.email}
            onChange={(n) => handleRegChange(n, "email")}
            invalid={!!errors.email}
          />
          <FormFeedback>{errors.email}</FormFeedback>
        </FormGroup>

        {/* password */}

        <FormGroup>
          <Label for="password">Password</Label>
          <Input
            id="password"
            name="password"
            placeholder="Enter password"
            type="text"
            value={regData.password}
            onChange={(n) => handleRegChange(n, "password")}
            invalid={!!errors.password}
          />
          <FormFeedback>{errors.password}</FormFeedback>
        </FormGroup>

        {/* name */}
        <Form>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              id="name"
              name="name"
              placeholder="Enter your name"
              type="text"
              value={employeeData.name}
              onChange={(n) => handleChange(n, "name")}
              invalid={!!errors.name}
            />
            <FormFeedback>{errors.name}</FormFeedback>
          </FormGroup>
          <FormGroup>
            <Label for="dateOfBirth">Select Date</Label>
            <Input
              type="date"
              name="dateOfBirth"
              id="dateOfBirth"
              placeholder="date placeholder"
              onChange={(d) => handleChange(d, "dateOfBirth")}
            />
          </FormGroup>

          <FormGroup>
            <Label for="genderSelect">Gender</Label>
            <Input
              id="genderSelect"
              name="genderSelect"
              type="select"
              value={employeeData.gender}
              onChange={(selectedOption) =>
                handleChange(selectedOption, "gender")
              }
            >
              {genders.map((gender, index) => (
                <option key={index} value={gender}>
                  {gender}
                </option>
              ))}
            </Input>
          </FormGroup>
          {/* email */}
          {/* <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="email_Address"
              name="email_Address"
              placeholder="Enter your email"
              type="email"
              value={employeeData.email_Address}
              onChange={(e) => handleChange(e, "email_Address")}
            />
          </FormGroup> */}
          {/* contact */}
          <FormGroup>
            <Label for="contact">Contact</Label>
            <Input
              id="phone_Number"
              name="phone_Number"
              placeholder="Enter your phone numer"
              type="number"
              value={employeeData.phone_Number}
              onChange={(c) => handleChange(c, "phone_Number")}
            />
          </FormGroup>

          {/* bank account number */}
          <FormGroup>
            <Label for="bank_acc_number">Bank Account Number</Label>
            <Input
              id="bank_acc_number"
              name="bank_acc_number"
              placeholder="Enter your account number"
              type="number"
              value={employeeData.bank_acc_number}
              onChange={(ban) => handleChange(ban, "bank_acc_number")}
            />
          </FormGroup>

          {/* salary */}
          <FormGroup>
            <Label for="salary">Salary</Label>
            <Input
              id="salary"
              name="salary"
              placeholder="Enter your salary"
              type="number"
              value={employeeData.salary}
              onChange={(sal) => handleChange(sal, "salary")}
            />
          </FormGroup>

          {/* emergency contact */}
          <FormGroup>
            <Label for="emergency_contact">Emergency Contact</Label>
            <Input
              id="emergency_contact"
              name="emergency_contact"
              placeholder="Enter your emergency contact"
              type="number"
              value={employeeData.emergency_contact}
              onChange={(ec) => handleChange(ec, "emergency_contact")}
            />
          </FormGroup>
          <FormGroup>
            <Label for="ImageFile">File</Label>
            <Input
              id="ImageFile"
              name="File"
              type="file"
              onChange={handleFileChange}
            />
            {/* <FormText>
              This is some placeholder block-level help text for the above
              input. It's a bit lighter and easily wraps to a new line.
            </FormText> */}
          </FormGroup>
        </Form>
        {/* submit botton */}
        {submitting ? (
          <Button className="btn" color="primary" disabled>
            <Spinner size="sm" color="light" />
            Submitting...
          </Button>
        ) : (
          <Button className="btn" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
        {/* <Button className="btn" color="primary" onClick={handleSubmit}>
          Submit
        </Button> */}
        <Link to="/employee-list">
          <Button className="btn" color="primary">
            Cancel
          </Button>
        </Link>
      </CardBody>
    </Card>
  );
};

export default AddEmployee;
