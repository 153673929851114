import { Card, CardBody, CardSubtitle, CardTitle } from "reactstrap";
import Chart from "react-apexcharts";
import { useEffect, useState } from "react";
import axios from "axios";
import axiosInstance from "../../axios";

const ExpenseChart = () => {
  const [datesList, setDatesList] = useState([]);
  const [incomeList, setIcomeList] = useState([]);

  useEffect(() => {
    const fetchIncome = async () => {
      const today = new Date();

      const todayMonth = today.getMonth() + 1;
      const todayYear = today.getFullYear();
      const month = `${todayYear}-${String(todayMonth).padStart(2, "0")}`;
      try {
        const response = await axiosInstance.get(
          `/api/ExpenseModels/${month}/${todayYear}`
        );
        if (response.status == 200) {
          setDatesList(response.data.dates);
          const roundedResponse = response.data.income.map((income) =>
            parseFloat(income.toFixed(2))
          );
          setIcomeList(roundedResponse);
        } else {
          console.log("Response : ", response.data);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    };
    fetchIncome();
  }, []);

  const chartoptions = {
    series: [
      {
        name: "Icome",
        data: [...incomeList],
      },
      //   {
      //     name: "Oneplue 9",
      //     data: [0, 11, 32, 45, 32, 34, 52, 41],
      //   },
    ],
    options: {
      chart: {
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      grid: {
        strokeDashArray: 3,
      },

      stroke: {
        curve: "smooth",
        width: 1,
      },
      xaxis: {
        // categories: [
        //   "Jan",
        //   "Feb",
        //   "March",
        //   "April",
        //   "May",
        //   "June",
        //   "July",
        //   "Aug",
        // ],
        categories: [...datesList],
      },
    },
  };
  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Income Summary</CardTitle>
        <CardSubtitle className="text-muted" tag="h6">
          Yearly Income Report
        </CardSubtitle>
        <Chart
          type="area"
          width="100%"
          height="390"
          options={chartoptions.options}
          series={chartoptions.series}
        ></Chart>
      </CardBody>
    </Card>
  );
};

export default ExpenseChart;
