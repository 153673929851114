import React, { useState, useEffect } from "react";

import {
  Card,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";

import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import { end } from "@popperjs/core";
import axiosInstance from "../../axios";

const GroupMembership = () => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [displayEndDate, setDisplayEndDate] = useState("");
  const { clientId } = useParams();
  console.log(clientId);
  const [membershipData, setMembershipData] = useState({
    cost: 0,
    membershipName: "Functional Group",
    sessionDuration: "0",
  });
  const [formErrors, setFormErrors] = useState({
    cost: false,
    membershipName: false,
    sessionDuration: false,
    startDate: false,
  });
  const [endDate, setEndDate] = useState("");

  const membership = ["Functional Group", "Strength and Conditioning", "Rehab"];
  const sessions = [
    "0",
    "1 month",
    "2 months",
    "3 months",
    "4 months",
    "5 months",
    "6 months",
    "7 months",
    "8 months",
    "9 months",
    "10 months",
    "11 months",
    "12 months",
  ];

  const today = new Date();

  const calculateDateToSend = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    console.log("CALCULATEDS DATE TO SEND");
    console.log(formattedDate);
    return formattedDate;
  };

  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setMembershipData({
      ...membershipData,
      [fieldName]: newValue,
    });
    if (fieldName === "startDate") {
      setStartDate(input.target.value); // Update the start date based on user selection
    }
    if (fieldName === "sessionDuration") {
      // Calculate end date based on session duration
      // const calculatedEndDate =
      calculateEndDate(newValue);
      // setEndDate(calculatedEndDate);
    }
  };

  const calculateEndDate = (option) => {
    console.log("calculate end date");
    const today = new Date(startDate);
    const endDate = new Date(today);
    switch (option) {
      case "1 month":
        endDate.setMonth(today.getMonth() + 1);
        break;
      case "2 months":
        endDate.setMonth(today.getMonth() + 2);
        break;
      case "3 months":
        endDate.setMonth(today.getMonth() + 3);
        break;
      case "4 months":
        endDate.setMonth(today.getMonth() + 4);
        break;
      case "5 months":
        endDate.setMonth(today.getMonth() + 5);
        break;
      case "6 months":
        endDate.setMonth(today.getMonth() + 6);
        break;
      case "7 months":
        endDate.setMonth(today.getMonth() + 7);
        break;
      case "8 months":
        endDate.setMonth(today.getMonth() + 8);
        break;
      case "9 months":
        endDate.setMonth(today.getMonth() + 9);
        break;
      case "10 months":
        endDate.setMonth(today.getMonth() + 10);
        break;
      case "11 months":
        endDate.setMonth(today.getMonth() + 11);
        break;
      case "12 months":
        endDate.setMonth(today.getMonth() + 12);
        break;
      default:
        return null;
    }

    const postFormattedEndDate = calculateDateToSend(endDate);

    // For display: MM/DD/YYYY
    const displayFormattedEndDate =
      (endDate.getMonth() + 1).toString().padStart(2, "0") +
      "/" +
      endDate.getDate().toString().padStart(2, "0") +
      "/" +
      endDate.getFullYear().toString();

    console.log(
      `Display End date for option '${option}':`,
      displayFormattedEndDate
    );

    // Update state for posting
    setEndDate(endDate);
    console.log("END DATE AFTER SETTING");
    console.log(endDate);
    // Update state for display
    setDisplayEndDate(displayFormattedEndDate);
  };

  const handleSubmit = async (e) => {
    let newFormErrors = { ...formErrors };
    if (membershipData.cost === 0) {
      newFormErrors.cost = true;
    } else {
      newFormErrors.cost = false;
    }

    if (membershipData.membershipName === "") {
      newFormErrors.membershipName = true;
    } else {
      newFormErrors.membershipName = false;
    }
    if (membershipData.sessionDuration === "0") {
      newFormErrors.sessionDuration = true;
    } else {
      newFormErrors.sessionDuration = false;
    }
    if (startDate === "") {
      newFormErrors.startDate = true;
    } else {
      newFormErrors.startDate = false;
    }
    setFormErrors(newFormErrors);
    if (
      membershipData.cost === 0 ||
      membershipData.membershipName === "" ||
      membershipData.sessionDuration === "0" ||
      startDate === ""
    ) {
      alert("Please fill the required fields.");
      return;
    } else {
      const today = new Date(startDate);
      const endDateSend = new Date(endDate);
      console.log("TODAY!!!!");
      console.log(today);
      console.log(endDateSend);

      setSubmitting(true);
      const jsonObject = {
        membershipCost: membershipData.cost,
        purchaseDate: calculateDateToSend(today),
        addO2MRelationshipDTO: {
          membershipName: membershipData.membershipName,
          sessionDuration: membershipData.sessionDuration,
          startDate: calculateDateToSend(today),
          endDate: calculateDateToSend(endDateSend),
        },
      };

      // useEffect(() => {
      //     const endDate = calculateEndDate();
      //     setEndDate(endDate);
      // }, []);
      try {
        console.log(jsonObject);
        const response = await axiosInstance.post(
          `/api/ClientInformation/GroupMembership/${clientId}`,
          JSON.stringify(jsonObject),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        if (response.status >= 200 && response.status < 300) {
          // Success
          alert("Data updated successfully!");
          navigate(-1);
        } else {
          // Display an error message
          alert("Failed to update data. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setSubmitting(false);
      }
    }
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Add Group Membership
      </CardTitle>
      <CardBody>
        <Form>
          {/* Membership Cost  */}
          <FormGroup>
            <Label for="cost">Membership Cost</Label>
            <Input
              id="cost"
              name="membershipCost"
              // placeholder="Membership Cost"
              type="text"
              value={membershipData.cost}
              style={{
                border: formErrors.cost ? "1px solid red" : "1px solid #ccc",
              }}
              onChange={(cost) => handleChange(cost, "cost")}
            />
          </FormGroup>
          {/* Membership Name */}
          <FormGroup>
            <Label for="name">Membership Name </Label>
            <Input
              id="name"
              name="membership-name"
              type="select"
              value={membershipData.membershipName}
              onChange={(selectedOption) =>
                handleChange(selectedOption, "membershipName")
              }
              style={{
                border: formErrors.membershipName
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
              // onChange={()}
            >
              {membership.map((gender, index) => (
                <option key={index} value={gender}>
                  {gender}
                </option>
              ))}
            </Input>
          </FormGroup>
          {/* Start Date */}
          <FormGroup>
            <Label for="start-date">Start Date</Label>
            <Input
              id="start-date"
              name="start-date"
              type="date" // Changed to "date" for a date picker
              value={startDate} // Controlled component with startDate state
              onChange={(date) => handleChange(date, "startDate")} // Handle changes
              style={{
                border: formErrors.startDate
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>

          {/* Session Count */}
          <FormGroup>
            <Label for="session-duration">Session Duration</Label>
            <Input
              id="session-duration"
              name="session-duration"
              placeholder="Select Session Duration"
              type="select"
              value={membershipData.sessionDuration}
              onChange={(sc) => handleChange(sc, "sessionDuration")}
              style={{
                border: formErrors.sessionDuration
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            >
              {sessions.map((session, index) => (
                <option key={index} value={session}>
                  {session}
                </option>
              ))}
            </Input>
          </FormGroup>

          {/* End Date */}
          <FormGroup>
            <Label for="end-date">End Date</Label>
            <Input
              id="end-date"
              name="end-date"
              type="text"
              value={displayEndDate}
              readOnly
              // onChange={()}
            />
          </FormGroup>
        </Form>
        {/* submit botton */}
        {submitting ? (
          <Button className="btn" color="primary" disabled>
            <Spinner size="sm" color="light" />
            Submitting...
          </Button>
        ) : (
          <Button className="btn" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
        {/* <Button className="btn" color="primary" onClick={handleSubmit}>
          Submit
        </Button> */}
      </CardBody>
    </Card>
  );
};

export default GroupMembership;
