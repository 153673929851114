import { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Form,
  Label,
  FormGroup,
  Input,
} from "reactstrap";
import axiosInstance from "../../axios";

const EditDocuments = () => {
  const [documentData, setDocumentData] = useState({
    cvLink: "",
    letterOfRecommendationLink: "",
    coverLetterLink: "",
    citizenshipLink: "",
    panLink: "",
  });

  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setDocumentData({
      ...documentData,
      [fieldName]: newValue,
    });
  };

  const handleFileChange = (e) => {
    setDocumentData({
      ...documentData,
      ImageFile: e.target.files[0],
    });
  };

  const postDocument = async () => {
    try {
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Edit Documents
      </CardTitle>

      <CardBody>
        <div
          style={{
            flex: 1,
            marginRight: "0.5rem",
            paddingRight: "0.5rem",
            borderRight: "1px solid #ccc",
          }}
        >
          <Form>
            {/* name */}
            <FormGroup>
              <Label for="cvLink">CV</Label>
              <Input
                id="cvLink"
                name="cvLink"
                placeholder="Provide CV"
                type="File"
                value={documentData.cvLink}
                onChange={handleFileChange}
              />
            </FormGroup>
          </Form>
        </div>
      </CardBody>
    </Card>
  );
};

export default EditDocuments;
