import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import FullLayout from "./layouts/FullLayout";
import Starter from "./views/Starter";
import Error from "./Error";
import About from "./views/About";
import Alerts from "./views/ui/Alerts";
import Buttons from "./views/ui/Buttons";
import Badges from "./views/ui/Badges";
import Breadcrumbs from "./views/ui/Breadcrumbs";
import Cards from "./views/ui/Cards";
import Grid from "./views/ui/Grid";
import Tables from "./views/ui/Tables";
import Forms from "./views/ui/Forms";
import CalendarComponent from "./views/Calender/CalendarComponent";
import EmployeeCalendar from "./views/Calender/EmployeeCalendar";
import Client from "./views/Client/Client";
import EditClient from "./views/Client/EditClient";
import ClientList from "./views/Client/ClientList";
import EmployeeList from "./views/Employee/Employee";
import AddEmployee from "./views/Employee/addEmployee";
import EditEmployee from "./views/Employee/editEmployee";
import AddMembership from "./views/Client/AddMembership";
// import SingleMembership from "./views/Client/SingleMembership";
// import GroupMembership from "./views/Client/GroupMembership";
import EditDocuments from "./views/Employee/editDocuments";
import LoginForm from "./views/Login";
import { AuthProvider, useAuth } from "./components/auth/AuthContext";
import EditSingleMembership from "./views/Client/EditSIngleMembership";
import EditGroupMembership from "./views/Client/EditGroupMembership";
// import ExpiredMembership from "./views/ui/ExpiredMembership";
// import News from "./pages/WebNews";
import Cookies from "js-cookie";
import ForgotPassword from "./views/ForgotPassword";
import ResetPassword from "./views/ResetPassword";
const PrivateRoute = ({ element, allowedRoles, ...rest }) => {
  const { isLoggedIn } = useAuth();
  const userRole = Cookies.get("userRole");

  console.log("PrivateRoute:", isLoggedIn, userRole);
  console.log("Allowed Roles:", allowedRoles);

  if (!isLoggedIn) {
    return <Navigate to="/login" />;
  }

  if (allowedRoles && !allowedRoles.includes(userRole)) {
    if (userRole === "Employee") {
      return <Navigate to="/employee-calender" />;
    }
    if (userRole === "FrontDesk") {
      return <Navigate to="/calender" />;
    }
    return <Navigate to="/dashboard" />;
  }

  return element;
};

function App() {
  return (
    <AuthProvider>
      <BrowserRouter>
        <Routes>
          {/* Website routes */}
          <Route path="/login" element={<LoginForm />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          {/* admin route */}
          <Route
            path="/"
            element={
              <PrivateRoute
                element={<FullLayout />}
                allowedRoles={["Admin", "FrontDesk", "Employee"]}
              />
            }
          >
            <Route
              path="/dashboard"
              element={
                <PrivateRoute element={<Starter />} allowedRoles={["Admin"]} />
              }
              allowedRoles={["Admin"]}
            />
            <Route path="about" element={<About />} />
            <Route path="alerts" element={<Alerts />} />
            <Route
              path="client-list"
              element={
                <PrivateRoute
                  element={<ClientList />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="client"
              element={
                <PrivateRoute
                  element={<Client />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="client-list/:clientId"
              element={
                <PrivateRoute
                  element={<EditClient />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="badges"
              element={
                <PrivateRoute element={<Badges />} allowedRoles={["Admin"]} />
              }
            />
            <Route
              path="buttons"
              element={
                <PrivateRoute element={<Buttons />} allowedRoles={["Admin"]} />
              }
            />
            <Route
              path="cards"
              element={
                <PrivateRoute element={<Cards />} allowedRoles={["Admin"]} />
              }
            />
            <Route
              path="table"
              element={
                <PrivateRoute element={<Tables />} allowedRoles={["Admin"]} />
              }
            />
            <Route
              path="forms"
              element={
                <PrivateRoute element={<Forms />} allowedRoles={["Admin"]} />
              }
            />
            <Route
              path="grid"
              element={
                <PrivateRoute element={<Grid />} allowedRoles={["Admin"]} />
              }
            />
            <Route
              path="breadcrumbs"
              element={
                <PrivateRoute
                  element={<Breadcrumbs />}
                  allowedRoles={["Admin"]}
                />
              }
            />
            <Route
              path="calender"
              element={
                <PrivateRoute
                  element={<CalendarComponent />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="employee-calender"
              element={
                <PrivateRoute
                  element={<EmployeeCalendar />}
                  allowedRoles={["Admin", "Employee"]}
                />
              }
            />
            <Route
              path="employee-list"
              element={
                <PrivateRoute
                  element={<EmployeeList />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="add-employee"
              element={
                <PrivateRoute
                  element={<AddEmployee />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="employee-list/:employeeID"
              element={
                <PrivateRoute
                  element={<EditEmployee />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="client-list/:clientId/add-membership"
              element={
                <PrivateRoute
                  element={<AddMembership />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="client-list/:clientId/edit-single-membership/:membershipID"
              element={
                <PrivateRoute
                  element={<EditSingleMembership />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="client-list/:clientId/edit-group-membership/:membershipID"
              element={
                <PrivateRoute
                  element={<EditGroupMembership />}
                  allowedRoles={["Admin", "FrontDesk"]}
                />
              }
            />
            <Route
              path="employee-list/:employeeID/edit-documents"
              element={
                <PrivateRoute
                  element={<EditDocuments />}
                  allowedRoles={["Admin"]}
                />
              }
            />
          </Route>
          <Route path="*" element={<h2>404 - Page Not Found</h2>} />
        </Routes>
      </BrowserRouter>
    </AuthProvider>
  );
}

export default App;
