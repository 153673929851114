import {
  Row,
  Col,
  Card,
  Button,
  // CardSubtitle
} from "reactstrap";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { useState } from "react";
import SingleMembership from "./SingleMembership";
import GroupMembership from "./GroupMembership";

const AddMembership = () => {
  const location = useLocation();
  const clientName = location.state ? location.state.clientName : null;
  console.log("clientName : ", clientName);

  // const navigate = useNavigate();
  // const navigatoToSingleMembership = () => {
  //   navigate("./single-membership", { state: { clientName: clientName } });
  // };

  const [isSingleMembership, setShowSingleMembershipFlag] = useState(true);
  const [active, setActive] = useState("OnetoOne");
  const [isGroupMembership, setShowGroupMembershipFlag] = useState(false);

  const showSingleMembership = () => {
    setActive("OnetoOne");
    setShowSingleMembershipFlag(true);
    setShowGroupMembershipFlag(false);
  };

  const showGroupMembership = () => {
    setActive("groupmember");
    setShowSingleMembershipFlag(false);
    setShowGroupMembershipFlag(true);
  };

  return (
    <div>
      <Card>
        <Row>
          {/* <p>Client Name: {clientName}</p> */}
          <Col>
            {/* <Link to="single-membership"> */}
            <Button
              className="btn"
              color="primary"
              style={
                active === "OnetoOne"
                  ? { backgroundColor: "blue", color: "white" , transition:'1s ease'}
                  : {}
              }
              // onClick={navigatoToSingleMembership}
              onClick={showSingleMembership}
            >
              One to one membership
            </Button>
            {/* </Link> */}
          </Col>

          <Col>
            {/* <Link to="group-membership"> */}
            <Button
              className="btn"
              color="primary"
              style={
                active === "groupmember"
                  ? { backgroundColor: "blue", color: "white", transition:'1s ease' }
                  : {}
              }
              onClick={showGroupMembership}
            >
              Group Membership
            </Button>
            {/* </Link> */}
          </Col>
        </Row>
      </Card>
      {isSingleMembership && <SingleMembership />}
      {isGroupMembership && <GroupMembership />}
    </div>
  );
};

export default AddMembership;
