import React, { createContext, useContext, useState, useEffect } from "react";
import Cookies from "js-cookie";
import axiosInstance from "../../axios";
const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(() => {
    return Cookies.get("token");
  });

  useEffect(() => {
    // Check if there's a token stored in cookies
    const token = Cookies.get("token");
    if (token) {
      setIsLoggedIn(true);
    }
  }, []);

  const login = async (username, password) => {
    try {
      console.log(username, password);
      const response = await axiosInstance.post(
        "/api/Authentication/login",
        { username, password },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      Cookies.set("token", response.data.token, { expires: 1 / 24 });
      Cookies.set("userRole", response.data.userRole, { expires: 1 / 24 });
      Cookies.set("userId", response.data.userId, { expires: 1 / 24 });
      Cookies.set("userName", response.data.userName, { expires: 1 / 24 });
      console.log(Cookies.get("token"));
      if (Cookies.get("userRole") == "Employee") {
        const employeeName = await axiosInstance.get(
          `/api/Employee/name/${Cookies.get("userId")}`
        );
        Cookies.set("Name", employeeName.data, { expires: 1 / 24 });
      }

      setIsLoggedIn(true);
      return response;
    } catch (error) {
      console.error("An error occurred during login.", error);
      throw error;
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
    Cookies.remove("token");
    Cookies.remove("userType");
    Cookies.remove("userId");
    Cookies.remove("userName");
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
