import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import axiosInstance from "../../axios";
import { useLocation } from "react-router-dom";

const EditSingleMembership = () => {
  const location = useLocation();
  const additionalData = location.state?.singleMembershipData;
  const clientName = location.state?.clientName;
  const clientId = location.state?.clientId;

  const [singleMembershipData, setSingleMembershipData] = useState({
    membershipID: additionalData.membershipID,
    membershipCost: additionalData.membershipCost,
    purchaseDate: additionalData.purchaseDate,
    membershipName: additionalData.o2Onerelationship.membershipName,
    sessionCount: additionalData.o2Onerelationship.sessionCount,
    startTime: additionalData.schedule.startTime,
    description: additionalData.schedule.description,
    doctorName: additionalData.schedule.doctorName,
    days: additionalData.days,
  });
  const [formErrors, setFormErrors] = useState({
    membershipCost: false,
    membershipName: false,
    sessionCount: false,
    startTime: false,
    description: false,
    doctorName: false,
    days: false,
  });
  const membershipName = ["Performance Training", "Treatment Sessions"];

  const [therapistNames, setTherapistNames] = useState([]);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Edit Single Membership Page%%%%%%%%%");
    const fetchTherapistNames = async () => {
      try {
        const response = await axiosInstance.get("/api/Employee/TherapistName");
        setTherapistNames(response.data); // Assuming the API returns an array of names
      } catch (error) {
        console.error("Failed to fetch therapist names:", error);
      }
    };

    fetchTherapistNames();
  }, []);

  const handleChange = (input, fieldName) => {
    let newValue = input.target ? input.target.value : input.label;
    console.log(newValue);

    // if (fieldName === "startTime" || fieldName === "endTime") {
    //   newValue = dateFormatter(newValue);
    // }
    if (fieldName === "startTime" || fieldName === "endTime") {
      newValue = input.target.value; // Use input target value directly for time fields
    } else if (fieldName.startsWith("checkbox-")) {
      // Handle checkbox inputs
      const updatedSelectedDays = daysOfWeek
        .map((day, index) => {
          const checkboxName = `checkbox-${index}`;
          if (document.getElementById(checkboxName).checked) {
            return index;
          }
          return null;
        })
        .filter((index) => index !== null);
      setSingleMembershipData({
        ...singleMembershipData,
        days: [updatedSelectedDays],
      });
      return;
    }

    setSingleMembershipData({
      ...singleMembershipData,
      [fieldName]: newValue,
    });
  };

  //date formatter
  const dateFormatter = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    dateTime.setHours(dateTime.getHours() + 1);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");
    const hours = String(dateTime.getHours()).padStart(2, "0");
    const minutes = String(dateTime.getMinutes()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDateTime;
  };

  const calculateDateToSend = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    console.log("CALCULATEDS DATE TO SEND");
    console.log(formattedDate);
    return formattedDate;
  };

  //put method for single membership
  const putSingleMembershipData = async () => {
    let newFormErrors = { ...formErrors };
    if (
      singleMembershipData.membershipCost === "0" ||
      singleMembershipData.membershipCost === ""
    ) {
      newFormErrors.membershipCost = true;
    } else {
      newFormErrors.membershipCost = false;
    }

    if (
      singleMembershipData.sessionCount === "0" ||
      singleMembershipData.sessionCount === ""
    ) {
      newFormErrors.sessionCount = true;
    } else {
      newFormErrors.sessionCount = false;
    }

    if (singleMembershipData.membershipName === "") {
      newFormErrors.membershipName = true;
    } else {
      newFormErrors.membershipName = false;
    }

    if (singleMembershipData.doctorName === "") {
      newFormErrors.doctorName = true;
    } else {
      newFormErrors.doctorName = false;
    }

    if (singleMembershipData.startTime === "") {
      newFormErrors.startTime = true;
    } else {
      newFormErrors.startTime = false;
    }

    if (singleMembershipData.endTime === "") {
      newFormErrors.endTime = true;
    } else {
      newFormErrors.endTime = false;
    }

    if (singleMembershipData.description === "") {
      newFormErrors.description = true;
    } else {
      newFormErrors.description = false;
    }

    if (singleMembershipData.days.length === 0) {
      newFormErrors.days = true;
    } else {
      newFormErrors.days = false;
    }
    setFormErrors(newFormErrors);
    if (
      singleMembershipData.membershipCost === "0" ||
      singleMembershipData.membershipCost === "" ||
      singleMembershipData.sessionCount === "0" ||
      singleMembershipData.sessionCount === "" ||
      singleMembershipData.membershipName === "" ||
      singleMembershipData.doctorName === "" ||
      singleMembershipData.startTime === "" ||
      singleMembershipData.endTime === "" ||
      singleMembershipData.description === "" ||
      singleMembershipData.selectedDays.length === 0
    ) {
      alert("Please fill the required fields. ");
      return;
    } else {
      const startDateTime = calculateDateToSend(singleMembershipData.startTime);
      const endDateTime = dateFormatter(singleMembershipData.startTime);
      const jsonObject = {
        membershipCost: singleMembershipData.membershipCost,
        purchaseDate: singleMembershipData.purchaseDate,
        scheduleModel: {
          // id: 0,
          doctorName: singleMembershipData.doctorName,
          clientName: clientName,
          startTime: startDateTime,
          endTime: endDateTime,
          description: singleMembershipData.description,
        },
        addO2ORelationshipDTO: {
          membershipName: singleMembershipData.membershipName,
          sessionCount: singleMembershipData.sessionCount,
          days: singleMembershipData.days[0],
        },
      };
      console.log("JSON OBJECT", jsonObject);
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          `/api/ClientInformation/SingleMembershipUpdate/ClientID=${clientId}/MembershipID=${additionalData.membershipID}/`,
          jsonObject,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status == 200) {
          alert("Membership updated successfully");
        } else {
          console.log("SErver Not found", response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Not found", error);
        } else {
          console.log("Error : ", error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Add One to one membership
      </CardTitle>
      <CardBody>
        <Form>
          {/* Membership Cost  */}
          <FormGroup>
            <Label for="cost">Membership Cost</Label>
            <Input
              id="cost"
              name="membershipCost"
              // placeholder="Membership Cost"
              type="text"
              value={singleMembershipData.membershipCost}
              onChange={(cost) => handleChange(cost, "membershipCost")}
              style={{
                border: formErrors.membershipCost
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>

          {/* Purchase Date */}
          <FormGroup>
            <Label for="purchase">Purchase Date</Label>
            <Input
              id="purchase"
              name="purchase-date"
              type="text"
              value={singleMembershipData.purchaseDate}
              readOnly
              // onChange={()}
            />
          </FormGroup>

          {/* Membership Name */}
          <FormGroup>
            <Label for="name">Membership Name </Label>
            <Input
              id="name"
              name="membership-name"
              type="select"
              value={singleMembershipData.membershipName}
              onChange={(selectedOption) =>
                handleChange(selectedOption, "membershipName")
              }
              style={{
                border: formErrors.membershipName
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
              // onChange={()}
            >
              {membershipName.map((gender, index) => (
                <option key={index} value={gender}>
                  {gender}
                </option>
              ))}
            </Input>
          </FormGroup>

          {/* Session Count */}
          <FormGroup>
            <Label for="session-count">Session Count</Label>
            <Input
              id="session-count"
              name="session-count"
              placeholder="0"
              type="number"
              value={singleMembershipData.sessionCount}
              onChange={(sc) => handleChange(sc, "sessionCount")}
              style={{
                border: formErrors.sessionCount
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>

          {/* Doctor Name */}
          <FormGroup>
            <Label for="doctor-name">Therapist Name</Label>
            <select
              id="doctor-name"
              name="doctor-name"
              value={singleMembershipData.doctorName}
              onChange={(sc) => handleChange(sc, "doctorName")}
              style={{
                width: "100%", // Adjust width to match the input field
                padding: "8px", // Adjust padding to match the input field
                margin: "0 0 15px", // Provide some bottom margin for spacing, adjust as necessary
                borderRadius: "4px", // Match the border radius of the input field
                border: formErrors.doctorName
                  ? "1px solid red"
                  : "1px solid #ccc", // Match the border of the input field
              }}
            >
              <option value="">Select a Therapist</option>
              {therapistNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </FormGroup>
          {/* stat data */}
          <FormGroup>
            <Label for="start-date">Start Date</Label>
            <Input
              type="datetime-local"
              name="start-date"
              id="start-date"
              placeholder="date placeholder"
              value={singleMembershipData.startTime}
              onChange={(d) => handleChange(d, "startTime")}
              style={{
                border: formErrors.startTime
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>
          {/* end data */}
          {/* <FormGroup>
                <Label for="end-date">End Date</Label>
                <Input
                  type="time"
                  name="end-date"
                  id="end-date"
                  placeholder="date placeholder"
                  value={membershipData.endTime}
                  onChange={(d) => handleChange(d, "endTime")}
                />
              </FormGroup> */}
          {/* //description */}
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              placeholder="Description goes here"
              value={singleMembershipData.description}
              onChange={(des) => handleChange(des, "description")}
              style={{
                border: formErrors.description
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>Days of the Week</Label>
            <div
              style={{ display: "flex", flexWrap: "wrap", maxWidth: "100%" }}
            >
              {daysOfWeek.map((day, index) => (
                <FormGroup check key={index} style={{ marginRight: "2rem" }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      id={`checkbox-${index}`}
                      name={`checkbox-${index}`}
                      checked={singleMembershipData.days[0]?.includes(index)}
                      value={day}
                      onChange={(e) => handleChange(e, `checkbox-${index}`)}
                      style={{
                        border: formErrors.days
                          ? "1px solid red"
                          : "1px solid #ccc",
                      }}
                    />
                    {day}
                  </Label>
                </FormGroup>
              ))}
            </div>
          </FormGroup>
        </Form>

        {/* submit botton */}
        {isLoading ? (
          <Button className="btn" color="primary" disabled>
            <Spinner size="sm" color="light" />
            Submitting...
          </Button>
        ) : (
          <Button
            className="btn"
            color="primary"
            onClick={putSingleMembershipData}
          >
            Submit
          </Button>
        )}
        {/* <Button className="btn" color="primary" onClick={handleSubmit}>
              Submit
            </Button> */}
      </CardBody>
    </Card>
  );
};
export default EditSingleMembership;
