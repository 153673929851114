
import { useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Spinner } from "reactstrap";
import axiosInstance from "../../axios";

const ExpiredMembership = () => {
  console.log("Expired Membership");
  const [expiredMembershipList, setExpiredMembershipList] = useState([]);
  const [expiredMembershipLoading, setExpiredMembershipLoading] =
    useState(false);

  useEffect(() => {
    const fetchExpiredData = async () => {
      try {
        setExpiredMembershipLoading(true);
        const response = await axiosInstance.get(
          "/api/ClientInformation/expire"
        );
        setExpiredMembershipLoading(false);
        if (response.status == 200) {
          setExpiredMembershipList(response.data);
        } else {
          console.log("Server Error : ", response.data);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    };

    fetchExpiredData();
  }, []);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    // Get day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // Add suffix to day
    let dayWithSuffix;
    switch (day) {
      case 1:
      case 21:
      case 31:
        dayWithSuffix = day + "st";
        break;
      case 2:
      case 22:
        dayWithSuffix = day + "nd";
        break;
      case 3:
      case 23:
        dayWithSuffix = day + "rd";
        break;
      default:
        dayWithSuffix = day + "th";
    }

    // Construct the formatted date
    const formattedDate = `${dayWithSuffix} ${month} ${year}`;
    return formattedDate;
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Memberships about to expire
      </CardTitle>
      <CardBody>
        {expiredMembershipLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner color="primary" />
          </div>
        ) : (
          <div>
            {expiredMembershipList.map((item, index) => (
              <Card key={index} className="shadow p-3 mb-5 bg-white rounded">
                <CardBody>
                  <CardTitle tag="h5">{item.clientName}</CardTitle>
                  <p>Membership Name: {item.membershipName}</p>
                  <p>
                    Last Session or End Date:{" "}
                    {formatDate(item.lastSessionOrEndDate)}
                  </p>
                </CardBody>
              </Card>
            ))}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

export default ExpiredMembership;
