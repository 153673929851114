import React, { useState, useEffect } from "react";

import {
  Card,
  CardTitle,
  CardBody,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Spinner,
  // CardSubtitle
} from "reactstrap";
import axiosInstance from "../../axios";

import { useParams, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";

const SingleMembership = () => {
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const { clientId } = useParams();
  console.log(clientId);
  const location = useLocation();
  const [therapistNames, setTherapistNames] = useState([]);
  const clientName = location.state ? location.state.clientName : null;
  const [membershipData, setMembershipData] = useState({
    cost: 0,
    sessionCount: 30,
    membership: "Performance Training",
    doctorName: "",
    startTime: "",
    endTime: "",
    description: "",
    selectedDays: [],
  });

  const membership = ["Performance Training", "Treatment Sessions"];

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Adding 1 because month is zero-based
  const day = String(today.getDate()).padStart(2, "0");
  const hours = String(today.getHours()).padStart(2, "0");
  const minutes = String(today.getMinutes()).padStart(2, "0");
  const seconds = String(today.getSeconds()).padStart(2, "0");
  const milliseconds = String(today.getMilliseconds()).padStart(3, "0");
  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
  const [formErrors, setFormErrors] = useState({
    cost: false,
    sessionCount: false,
    membership: false,
    doctorName: false,
    startTime: false,
    endTime: false,
    description: false,
    selectedDays: false,
  });
  useEffect(() => {
    const fetchTherapistNames = async () => {
      try {
        const response = await axiosInstance.get("/api/Employee/TherapistName");
        setTherapistNames(response.data); // Assuming the API returns an array of names
      } catch (error) {
        console.error("Failed to fetch therapist names:", error);
      }
    };

    fetchTherapistNames();
  }, []); // Empty dependency array means this effect runs once on mount

  const handleChange = (input, fieldName) => {
    let newValue = input.target ? input.target.value : input.label;
    console.log(newValue);

    // if (fieldName === "startTime" || fieldName === "endTime") {
    //   newValue = dateFormatter(newValue);
    // }
    if (fieldName === "startTime" || fieldName === "endTime") {
      newValue = input.target.value; // Use input target value directly for time fields
    }

    setMembershipData({
      ...membershipData,
      [fieldName]: newValue,
    });
  };
  const handleCheckboxChange = (day) => {
    const newSelectedDays = [...membershipData.selectedDays];
    if (newSelectedDays.includes(day)) {
      const index = newSelectedDays.indexOf(day);
      newSelectedDays.splice(index, 1);
    } else {
      newSelectedDays.push(day);
    }

    setMembershipData({
      ...membershipData,
      selectedDays: newSelectedDays,
    });
  };
  const handleSubmit = async (e) => {
    // const formDataToSend = new FormData();
    // formDataToSend.append("membershipCost", 100);
    // formDataToSend.append("purchaseDate", formattedDate);
    // formDataToSend.append("addO2ORelationshipDTO['membershipName']", "Single Membership");
    // formDataToSend.append("addO2ORelationshipDTO['sessionCount']", membershipData.sessionCount);
    // const jsonObject = {};
    // for (const [key, value] of formDataToSend) {
    //     jsonObject[key] = value;
    // }
    let newFormErrors = { ...formErrors };

    if (membershipData.cost === 0) {
      newFormErrors.cost = true;
    } else {
      newFormErrors.cost = false;
    }

    if (membershipData.sessionCount === 0) {
      newFormErrors.sessionCount = true;
    } else {
      newFormErrors.sessionCount = false;
    }

    if (membershipData.membership === "") {
      newFormErrors.membership = true;
    } else {
      newFormErrors.membership = false;
    }

    if (membershipData.doctorName === "") {
      newFormErrors.doctorName = true;
    } else {
      newFormErrors.doctorName = false;
    }

    if (membershipData.startTime === "") {
      newFormErrors.startTime = true;
    } else {
      newFormErrors.startTime = false;
    }

    if (membershipData.endTime === "") {
      newFormErrors.endTime = true;
    } else {
      newFormErrors.endTime = false;
    }

    if (membershipData.description === "") {
      newFormErrors.description = true;
    } else {
      newFormErrors.description = false;
    }

    if (membershipData.selectedDays.length === 0) {
      newFormErrors.selectedDays = true;
    } else {
      newFormErrors.selectedDays = false;
    }
    setFormErrors(newFormErrors);

    if (
      membershipData.cost === 0 ||
      membershipData.sessionCount === 0 ||
      membershipData.membership === "" ||
      membershipData.doctorName === "" ||
      membershipData.startTime === "" ||
      membershipData.endTime === "" ||
      membershipData.description === "" ||
      membershipData.selectedDays.length === 0
    ) {
      alert("Please fill the required fields. ");
      return;
    } else {
      setLoading(true);

      const startDateTime = dateFormatter(membershipData.startTime);
      // Assuming membershipData.endTime is a date-time string that can be parsed by the Date constructor
      const endDateTime = dateFormatter(membershipData.startTime);
      const updatedSelectedDays = daysOfWeek
        .map((day, index) => {
          const checkboxName = `checkbox-${index}`;
          if (document.getElementById(checkboxName).checked) {
            return index;
          }
          return null;
        })
        .filter((index) => index !== null);
      console.log("seelcted days: ", updatedSelectedDays);
      console.log("seelcted days: ", updatedSelectedDays);

      const jsonObject = {
        membershipCost: membershipData.cost,
        purchaseDate: formattedDate,
        scheduleModel: {
          id: 0,
          doctorName: membershipData.doctorName,
          clientName: clientName,
          startTime: startDateTime,
          endTime: endDateTime,
          description: membershipData.description,
        },
        addO2ORelationshipDTO: {
          membershipName: membershipData.membership,
          sessionCount: membershipData.sessionCount,
          days: updatedSelectedDays,
        },
      };

      try {
        console.log(jsonObject);
        const response = await axiosInstance.post(
          `/api/ClientInformation/SingleMembership/${clientId}`,
          JSON.stringify(jsonObject),
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        if (response.status >= 200 && response.status < 300) {
          // Success
          alert("Data updated successfully!");
          navigate(-1);
        } else if (response.status == 409) {
          alert("Therapist schedule slot is unavailable");
        } else {
          // Display an error message
          alert("Failed to update data. Please try again.");
        }
      } catch (error) {
        console.error("Error:", error);
      } finally {
        setLoading(false);
      }
    }
  };

  const dateFormatter = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    dateTime.setHours(dateTime.getHours() + 1);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");
    const hours = String(dateTime.getHours()).padStart(2, "0");
    const minutes = String(dateTime.getMinutes()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDateTime;
  };
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Add One to one membership
      </CardTitle>
      <CardBody>
        <Form>
          {/* Membership Cost  */}
          <FormGroup>
            <Label for="cost">Membership Cost</Label>
            <Input
              id="cost"
              name="membershipCost"
              // placeholder="Membership Cost"
              type="text"
              value={membershipData.cost}
              onChange={(cost) => handleChange(cost, "cost")}
              style={{
                border: formErrors.cost ? "1px solid red" : "1px solid #ccc",
              }}
            />
          </FormGroup>

          {/* Purchase Date */}
          <FormGroup>
            <Label for="purchase">Purchase Date</Label>
            <Input
              id="purchase"
              name="purchase-date"
              type="text"
              value={formattedDate}
              readOnly
              // onChange={()}
            />
          </FormGroup>

          {/* Membership Name */}
          <FormGroup>
            <Label for="name">Membership Name </Label>
            <Input
              id="name"
              name="membership-name"
              type="select"
              value={membershipData.membership}
              onChange={(selectedOption) =>
                handleChange(selectedOption, "membership")
              }
              style={{
                border: formErrors.membership
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
              // onChange={()}
            >
              {membership.map((gender, index) => (
                <option key={index} value={gender}>
                  {gender}
                </option>
              ))}
            </Input>
          </FormGroup>

          {/* Session Count */}
          <FormGroup>
            <Label for="session-count">Session Count</Label>
            <Input
              id="session-count"
              name="session-count"
              placeholder="0"
              type="number"
              value={membershipData.sessionCount}
              onChange={(sc) => handleChange(sc, "sessionCount")}
              style={{
                border: formErrors.sessionCount
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>

          {/* Doctor Name */}
          <FormGroup>
            <Label for="doctor-name">Therapist Name</Label>
            <select
              id="doctor-name"
              name="doctor-name"
              value={membershipData.doctorName}
              onChange={(sc) => handleChange(sc, "doctorName")}
              style={{
                width: "100%", // Adjust width to match the input field
                padding: "8px", // Adjust padding to match the input field
                margin: "0 0 15px", // Provide some bottom margin for spacing, adjust as necessary
                borderRadius: "4px", // Match the border radius of the input field
                border: formErrors.doctorName
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            >
              <option value="">Select a Therapist</option>
              {therapistNames.map((name, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
            </select>
          </FormGroup>
          {/* stat data */}
          <FormGroup>
            <Label for="start-date">Start Date</Label>
            <Input
              type="datetime-local"
              name="start-date"
              id="start-date"
              placeholder="date placeholder"
              value={membershipData.startTime}
              onChange={(d) => handleChange(d, "startTime")}
              style={{
                border: formErrors.startTime
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>
          {/* end data */}
          <FormGroup>
            <Label for="end-date">End Date</Label>
            <Input
              type="datetime-local"
              name="end-date"
              id="end-date"
              placeholder="date placeholder"
              value={membershipData.endTime}
              onChange={(d) => handleChange(d, "endTime")}
              style={{
                border: formErrors.endTime ? "1px solid red" : "1px solid #ccc",
              }}
            />
          </FormGroup>
          {/* //description */}
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              value={membershipData.description}
              placeholder="Description goes here"
              onChange={(des) => handleChange(des, "description")}
              style={{
                border: formErrors.description
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>

          <FormGroup>
            <Label>Days of the Week</Label>
            <div style={{ display: "flex" }}>
              {daysOfWeek.map((day, index) => (
                <FormGroup check key={index} style={{ marginRight: "2rem" }}>
                  <Label check>
                    <Input
                      type="checkbox"
                      id={`checkbox-${index}`}
                      name={`checkbox-${index}`}
                      value={day}
                      onChange={() => handleCheckboxChange(day)}
                      style={{
                        border: formErrors.selectedDays
                          ? "1px solid red"
                          : "1px solid #ccc",
                      }}
                      //   onChange={(e) =>
                      //     handleChange(e, `checkbox-${index}`, index)
                      //   }
                    />
                    {day}
                  </Label>
                </FormGroup>
              ))}
            </div>
          </FormGroup>
        </Form>

        {/* submit botton */}
        {isLoading ? (
          <Button className="btn" color="primary" disabled>
            <Spinner size="sm" color="light" />
            Submitting...
          </Button>
        ) : (
          <Button className="btn" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
        {/* <Button className="btn" color="primary" onClick={handleSubmit}>
          Submit
        </Button> */}
      </CardBody>
    </Card>
  );
};

export default SingleMembership;
