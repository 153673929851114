import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import styles from "./CalendarComponent.module.css";
import Modal from "react-modal";
import axios from "axios";
import Cookies from "js-cookie";
import axiosInstance from "../../axios";

Modal.setAppElement("#root");

const EmployeeCalendar = () => {
  const [showModal, setShowModal] = useState(false);
  const [clickedEventId, setClickedEventId] = useState(null);

  const [formData, setFormData] = useState({
    id: 0,
    doctorName: "",
    clientName: "",
    startTime: "",
    endTime: "",
    description: "",
  });

  const handleDateSelect = () => {
    setShowModal(true);

    setClickedEventId(null);
  };

  const handleEventClick = (clickInfo) => {
    console.log("handleEventClick called");
    console.log("Clicked Event Object:", clickInfo.event);

    const { doctorName, clientName, start, end, description, sourceId } =
      clickInfo.event.extendedProps;
    const id = clickInfo.event.id;
    console.log("Clicked Event ID:", id);
    console.log("Clicked Event Data:", clickInfo.event.extendedProps);
    console.log("Form Data After Update:", formData);

    setFormData((prevData) => ({
      ...prevData,
      id,
      doctorName,
      clientName,
      startTime: start,
      endTime: end,
      description,
    }));
    setShowModal(true);

    setClickedEventId(id);
  };

  const closeModal = () => {
    setShowModal(false);

    setFormData({
      id: 0,
      doctorName: "",
      clientName: "",
      startTime: "",
      endTime: "",
      description: "",
    });
    setClickedEventId(null);
  };

  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    console.log("Clicked Event ID:", clickedEventId);
    console.log("Clicked Event Data:", clickedEventId);
    console.log("Form Data After Update:", formData);

    fetchAppointments();
  }, [clickedEventId, formData]);

  const fetchAppointments = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/Schedule/GetScheduleOfDoctor?docName=${Cookies.get("Name")}`
      );
      setAppointments(response.data);
      const fetchedAppointments = response.data;

      console.log("Fetched Appointments:", fetchedAppointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  return (
    <div>
      <FullCalendar
        select={handleDateSelect}
        eventClick={handleEventClick}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        events={appointments.map((appointment) => ({
          id: appointment.id.toString(),
          title: appointment.doctorName,
          start: appointment.startTime,
          end: appointment.endTime,
          description: appointment.description,
          clientName: appointment.clientName,
        }))}
      />
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Appointment Details"
      >
        <div className={styles.calendarForm}>
          <h2>{"Appointment Details"}</h2>

          <div style={{ display: "flex", alignItems: "center" }}>
            <h6 style={{ marginRight: "10px" }}>Client Name:</h6>
            <p>{formData.clientName}</p>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <h6 style={{ marginRight: "10px" }}>Start Time:</h6>
            <p>{formData.startTime}</p>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <h6 style={{ marginRight: "10px" }}>End Time:</h6>
            <p>{formData.endTime}</p>
          </div>

          <div style={{ display: "flex", alignItems: "center" }}>
            <h6 style={{ marginRight: "10px" }}> Description:</h6>
            <p>{formData.description}</p>
          </div>

          {/* <form onSubmit={handleFormSubmit}> */}
          {/* <label>
              Doctor Name:
              <input
                type="text"
                name="doctorName"
                value={formData.doctorName}
                onChange={handleInputChange}
              />
            </label> */}

          {/* <label>
              Client Name:
              <input
                type="text"
                name="clientName"
                value={formData.clientName}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Start Time:
              <input
                type="date"
                name="startTime"
                value={formData.startTime}
                onChange={handleInputChange}
              />
            </label>
            <label>
              End Time:
              <input
                type="date"
                name="endTime"
                value={formData.endTime}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Description:
              <textarea
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </label> */}
          {/* <button type="submit">
              {editMode ? "Update Appointment" : "Save Appointment"}
            </button> */}
          {/* <button type="button" onClick={closeModal}>
              Cancel
            </button>
          </form> */}
          <button type="button" onClick={closeModal}>
            Cancel
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default EmployeeCalendar;
