import { Col, Row } from "reactstrap";
import SalesChart from "../components/dashboard/SalesChart";
import Feeds from "../components/dashboard/Feeds";
import ProjectTables from "../components/dashboard/ProjectTable";
import TopCards from "../components/dashboard/TopCards";
import DoughnutChart from "../components/dashboard/DoughnutChart";
import bg1 from "../assets/images/bg/bg1.jpg";
import bg2 from "../assets/images/bg/bg2.jpg";
import bg3 from "../assets/images/bg/bg3.jpg";
import bg4 from "../assets/images/bg/bg4.jpg";
import { useEffect, useState } from "react";
import axios from "axios";
import ExpenseChart from "../components/dashboard/ExpenseChart";
import ExpiredMembership from "./ui/ExpiredMembership";
import axiosInstance from "../axios";

const Starter = () => {
  const [incomeData, setIncomeData] = useState({
    yearlyIncome: 0,
    monthlyIncome: 0,
    totalCustomer: 0,
    performance_Training: 0,
    treatment_Sessions: 0,
    functional_Group: 0,
    strength_and_Conditioning: 0,
    rehab: 0,
  });

  // const [currentDate, setCurrentDate] = useState({
  //   month: "",
  //   year: "",
  // });

  useEffect(() => {
    const fetchExpenseData = async () => {
      const today = new Date();
      console.log("today", today);
      const todayMonth = today.getMonth() + 1;
      const todayYear = today.getFullYear();
      const month = `${todayYear}-${String(todayMonth).padStart(2, "0")}`;
      // setCurrentDate((prevState) => ({
      //   ...prevState,
      //   month: `${todayYear}-${todayMonth}`,
      //   year: todayYear,
      // }));
      console.log("current month", month);
      console.log("current month", todayYear);

      try {
        const response = await axiosInstance.get(
          `/api/ExpenseModels/${month}/${todayYear}`
        );
        if (response.status == 200) {
          setIncomeData({
            yearlyIncome: response.data.yearlyIncome.toFixed(2) ?? 0,
            monthlyIncome: response.data.monthlyIncome.toFixed(2) ?? 0,
            totalCustomer: response.data.totalCustomer,
            performance_Training: response.data.performance_Training,
            treatment_Sessions: response.data.treatment_Sessions,
            functional_Group: response.data.functional_Group,
            strength_and_Conditioning: response.data.strength_and_Conditioning,
            rehab: response.data.rehab,
          });
        } else {
          console.log("Response : ", response.data);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    };

    fetchExpenseData();
  }, []);

  return (
    <div>
      {/***Top Cards***/}
      <Row>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-success text-success"
            title="Profit"
            subtitle="Yearly Earning"
            earning={`Rs ${incomeData.yearlyIncome}`}
            icon="bi bi-wallet"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-danger text-danger"
            title="Refunds"
            subtitle="Monthly Income"
            earning={`Rs ${incomeData.monthlyIncome}`}
            icon="bi bi-coin"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-warning text-warning"
            title="New Project"
            subtitle="Total Customers"
            earning={incomeData.totalCustomer}
            icon="bi bi-basket3"
          />
        </Col>
        <Col sm="6" lg="3">
          <TopCards
            bg="bg-light-info text-into"
            title="Sales"
            subtitle="Weekly Customers"
            earning="210"
            icon="bi bi-bag"
          />
        </Col>
      </Row>
      {/***Sales & Feed***/}
      <Row>
        <Col sm="6" lg="6" xl="7" xxl="8">
          <ExpenseChart />
        </Col>
        <Col sm="6" lg="6" xl="5" xxl="4">
          <DoughnutChart incomeData={incomeData} />
        </Col>
      </Row>
      {/***Table ***/}
      <Row>
        <Col lg="12">
          <ExpiredMembership />
        </Col>
      </Row>
      {/* <Row>
        <Col lg="12">
          <ProjectTables />
        </Col>
      </Row> */}
    </div>
  );
};

export default Starter;
