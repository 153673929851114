import React, { useState } from "react";
import "./ResetPassword.css";
import logo from "../assets/images/logos/logo.png";
import { Spinner, Button } from "reactstrap";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faLock, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();

  const goBack = () => {
    navigate("/login");
  };
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get("token");
  const email = queryParams.get("email");

  const [passwordData, setPasswordData] = useState({
    password: "",
    confirmPassword: "",
    email: email || "",
    token: token || "",
  });
  const handlePasswordChange = (e) => {
    const { name, value } = e.target;
    setPasswordData({
      ...passwordData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading(true);
      console.log("fetching");
      const response = await fetch(
        `/api/Authentication/reset-password?token=${token}&email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(passwordData),
        }
      );
      setLoading(false);

      if (response.ok) {
        setTimeout(() => {
          navigate("/");
        });
        alert("Password reset successful!");
      } else {
        alert(`Link has been expired`);
      }
    } catch (error) {
      alert("An error occurred during password reset.");
    }
  };

  return (
    <div className="reset-password-container">
      <img src={logo} alt="Logo" className="logo" />
      <FontAwesomeIcon
        icon={faArrowLeft}
        className="arrow-icon"
        onClick={goBack}
      />
      <div className="password-form-container">
        <h2>Reset Password</h2>
        <p style={{ fontSize: "18px" }}>Enter your new password to reset it.</p>
        <form className="reset-password-form" onSubmit={handleSubmit}>
          <div>
            <label>New Password:</label>
            <input
              type={showPassword ? "text" : "password"}
              name="password"
              value={passwordData.password}
              onChange={handlePasswordChange}
              placeholder="new-password"
              required
            />
          </div>
          <div>
            <label>Confirm Password:</label>
            <input
              type={showConfirmPassword ? "text" : "password"}
              name="confirmPassword"
              value={passwordData.confirmPassword}
              onChange={handlePasswordChange}
              placeholder="confirm password"
              required
            />
          </div>

          {loading ? (
            <Button className="btn" color="primary" disabled>
              <Spinner size="sm" color="light" />
              Submitting...
            </Button>
          ) : (
            <button type="submit" onClick={handleSubmit}>
              Submit
            </button>
          )}
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
