import axios from "axios";
import Cookies from "js-cookie";

// Create a custom Axios instance
const axiosInstance = axios.create({
  baseURL: "", // Your base URL here
});

// Use an interceptor to set the Authorization header before each request
axiosInstance.interceptors.request.use(
  (config) => {
    // Retrieve the token just before the request is sent
    const token = Cookies.get("token");
    // Set the Authorization header with the current token
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
