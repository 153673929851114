// import React, { useEffect, useState } from 'react';
// import { Link } from 'react-router-dom';
// import axios from 'axios';

// import { Form } from "react-router-dom";
import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Spinner,
} from "reactstrap";
// import bg3 from "../../../assets/images/bg/bg3.jpg";
import { useParams, Link, json, useNavigate } from "react-router-dom";
import CalendarHeatmap from "react-calendar-heatmap";
import "./employee-calendar.css";
import { contains } from "jquery";
import Modal from "react-modal";
import styles from "./employee-form.module.css";
import { resetErrorsCount } from "ajv/dist/compile/errors";
import Tooltip from "@uiw/react-tooltip";
import HeatMap from "@uiw/react-heat-map";
import axiosInstance from "../../axios";

const EditEmployee = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [adding, setAdding] = useState(false);
  const [takingLeave, setLeaveFlag] = useState(false);
  const genders = ["Male", "Female", "Other"];
  const { employeeID } = useParams();
  const [employeeData, setEmployeeData] = useState({
    employeeID: employeeID,
    name: "",
    dateOfBirth: "",
    gender: "male",
    phone_Number: "",
    email_Address: "",
    bank_acc_number: "",
    emergency_contact: "",
    salary: 0.0,
    ImageFile: "",
    ImageLink: "",
    leaveBalance: 0,
  });

  const [notFoundFlag, setNotFoundFlag] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/Employee/${employeeID}`
          // `/api/AddressModels/${cookieValue}`
        );
        const birthDate = new Date(response.data.dateOfBirth);

        // Adjust for timezone offset
        const timeZoneOffset = birthDate.getTimezoneOffset() * 60000; // convert minutes to milliseconds
        const adjustedDate = new Date(birthDate.getTime() - timeZoneOffset);
        // console.log("get : " + response.data);
        console.log("bank  ko acc number");
        console.log(response.data.bankAccountNumber);
        setEmployeeData({
          name: response.data.name,
          dateOfBirth: adjustedDate.toISOString().split("T")[0],
          gender: response.data.gender,
          phone_Number: response.data.phone_Number,
          email_Address: response.data.email_Address,
          bank_acc_number: response.data.bankAccountNumber ?? "",
          emergency_contact: response.data.emergency_Contact,
          salary: response.data.salary,
          ImageFile: response.data.imageFile,
          ImageLink: response.data.imageLink,
          leaveBalance: response.data.leaveBalance ?? 0,
        });
        setNotFoundFlag(0);
      } catch (error) {
        //console.error("Error:", error);
        // Handle error
        if (error.response && error.response.status === 404) {
          // Set the notFoundFlag to 1 if it's a 404 error
          setNotFoundFlag(1);
        } else {
          // Handle other errors
          setNotFoundFlag(0);
        }
      }
    };

    fetchData();
  }, [employeeID]);
  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setEmployeeData({
      ...employeeData,
      [fieldName]: newValue,
    });
  };

  const handleFileChange = (e) => {
    setEmployeeData({
      ...employeeData,
      ImageFile: e.target.files[0],
    });
  };
  const handleSubmit = async (e) => {
    console.log("buttone cloieadfa");
    console.log(employeeData.bank_acc_number);
    console.log(employeeID);
    e.preventDefault();
    setLoading(true);
    const formDataToSend = new FormData();
    formDataToSend.append("employeeID", employeeID);
    formDataToSend.append("name", employeeData.name);
    formDataToSend.append("dateOfBirth", employeeData.dateOfBirth);
    formDataToSend.append("gender", employeeData.gender);
    formDataToSend.append("phone_Number", employeeData.phone_Number);
    formDataToSend.append("email_Address", employeeData.email_Address);
    formDataToSend.append("bankAccountNumber", employeeData.bank_acc_number);
    formDataToSend.append("emergency_Contact", employeeData.emergency_contact);
    formDataToSend.append("salary", employeeData.salary);
    formDataToSend.append("imageFile", employeeData.ImageFile);
    if (notFoundFlag === 1) {
      // personalInfo is empty, data hasn't been fetched, handle accordingly
      console.log("DATA IS EMPTY");
      try {
        console.log(formDataToSend.data);
        await axiosInstance.post(`/api/Employee/${employeeID}`, formDataToSend);
      } catch (error) {}
      return;
    }
    console.log("put req");
    try {
      const response = await axiosInstance.put(
        `/api/Employee/${employeeID}`,
        formDataToSend
      ); // Assuming a PUT request for updating
      console.log("Response:", response.data);
      if (response.status >= 200 && response.status < 300) {
        // Success
        alert("Data updated successfully!");
        navigate(-1);
      } else {
        // Display an error message
        alert("Failed to update data. Please try again.");
      }
      // Add any additional logic you need after a successful submission
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  //get employee attendance
  const [employeeAttendance, setEmployeeAttendance] = useState([]);
  useEffect(() => {
    console.log("employee attendance fetched");
    const fetchEmployeeAttendance = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/Employee/EmployeeAttendance/${employeeID}`
        );
        if (response.status === 200) {
          const attendanceData = response.data.map((attendance) => {
            return {
              date: calcFormattedDate(attendance.date),
              status: attendance.status, // Assuming your API returns this
            };
          });
          setEmployeeAttendance(attendanceData);
        } else {
          console.log("Server Error", response.data);
        }
      } catch (error) {
        console.log("Error : ", error);
      }
    };

    fetchEmployeeAttendance();
  }, []);

  //convert date
  const calcFormattedDate = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const [attendance, setAttendance] = useState({
    status: "Present",
    remarks: "",
  });

  //add attendacne
  const addAttendance = async () => {
    console.log("add attendance");
    setAdding(true);
    try {
      const jsonObject = {
        status: attendance.status,
        remarks: attendance.remarks,
      };
      const response = await axiosInstance.post(
        `/api/Employee/AddAttendance/${employeeID}`,
        JSON.stringify(jsonObject),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      closeModal();
      if (response.status === 200) {
        alert("Attendance added successfully.");
      } else if (response.status === 400) {
        alert("Attendance Already Taken.");
      } else {
        alert("Something went wrong.");
      }
    } catch (error) {
      console.log("Error : ", error);
      closeModal();
    } finally {
      setAdding(false);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e) => {
    setAttendance({ ...attendance, [e.target.name]: e.target.value });
  };

  //take leave
  // const takeLeave = async () => {
  //   setLeaveFlag(true);
  //   try {
  //     const response = await axiosInstance.put(
  //       `/api/Employee/DecrementLeave/${employeeID}`
  //     );
  //     if (response.status == 200) {
  //       alert("Leave taken successfully.");
  //     } else if (response.status == 400) {
  //       alert("No more leaves");
  //     } else {
  //       alert("Something went wrong.");
  //     }
  //   } catch (error) {
  //     console.log("Error : ", error);
  //   } finally {
  //     setLeaveFlag(false);
  //   }
  // };

  //view documents details
  const [showDocuments, setDocumentsFlag] = useState(false);
  const [document, setDocument] = useState({
    cvLink: "",
    letterOfRecommendationLink: "",
    coverLetterLink: "",
    citizenshipLink: "",
    panLink: "",
  });
  const fetchDoucuments = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/Employee/Documents/${employeeID}`
      );
      if (response.status == 200) {
        setDocument(response.data);
      } else {
        alert("Failed to load documents");
      }
    } catch (error) {
      console.log("Error : ", error);
    }
  };

  const openDocuments = async () => {
    await fetchDoucuments();
    setDocumentsFlag(true);
  };

  const closeDocuments = () => {
    setDocumentsFlag(false);
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const ConfirmationPopup = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="popup-overlay">
        <div className="popup">
          <p>Are you sure you want to delete the record?</p>
          <div className="button-container">
            <button onClick={onConfirm}>Yes</button>
            <button onClick={onClose}>No</button>
          </div>
        </div>
      </div>
    );
  };
  const handleDelete = async (e) => {
    // Delete record logic goes here
    console.log("Record deleted");
    try {
      const response = await axiosInstance.delete(
        `/api/Employee/${employeeID}`
      );
      console.log("fetched");
      console.log(response);

      if (response.status === 204) {
        alert("Deleted Successfully");
        console.log("working!!!!!!!!!!");
        navigate("/employee-list");
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log("error : ", error);
    }
    setIsPopupOpen(false);
  };

  return (
    <div>
      <Card>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-bell me-2"> </i>
            Employee Attendance Calendar
          </CardTitle>
          {/* <Button
            className="btn my-3"
            color="danger"
            onClick={() => setIsPopupOpen(true)}
          >
            Delete
          </Button> */}
          <ConfirmationPopup
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            onConfirm={handleDelete}
          />

          {/* {takingLeave ? (
            <Button className="btn" color="primary" disabled>
              <Spinner size="sm" color="light" />
              Taking Leave...
            </Button>
          ) : (
            <Button className="btn" color="primary" onClick={takeLeave}>
              Take Leave
            </Button>
          )} */}
          {/* <Button className="btn" color="primary" onClick={takeLeave}>
            Take Leave
          </Button> */}

          <Button className="btn my-3" color="primary" onClick={openModal}>
            Add Attendance
          </Button>

          <Modal
            isOpen={showModal}
            onRequestClose={closeModal}
            contentLabel="Add Attendance"
          >
            <div className={styles.attendanceCalendarForm}>
              <form onSubmit={addAttendance}>
                <label>
                  Status:
                  <select
                    name="status"
                    value={attendance.status}
                    onChange={handleInputChange}
                    style={{
                      width: "100%", // Adjust width to match the input field
                      padding: "8px", // Adjust padding to match the input field
                      margin: "0 0 15px", // Provide some bottom margin for spacing, adjust as necessary
                      borderRadius: "4px", // Match the border radius of the input field
                      border: "1px solid #ccc", // Match the border of the input field
                    }}
                  >
                    <option value="Present">Present</option>
                    <option value="Leave">Leave</option>
                  </select>
                </label>
                <label>
                  Remarks:
                  <input
                    type="text"
                    name="remarks"
                    placeholder="remarks"
                    value={attendance.remarks}
                    onChange={handleInputChange}
                  />
                </label>
                {adding ? (
                  <Button className="btn" color="primary" disabled>
                    <Spinner size="sm" color="light" />
                    Adding...
                  </Button>
                ) : (
                  <Button
                    className="btn"
                    color="primary"
                    onClick={addAttendance}
                  >
                    Add Attendance
                  </Button>
                )}
                {/* <button type="button" onClick={addAttendance}>
                  Add Attendance
                </button> */}
                <button type="button" onClick={closeModal}>
                  Cancel
                </button>
              </form>
            </div>
          </Modal>
        </div>

        <CardBody
          style={{
            display: "flex",
            height: "220px",
            width: "100%",
            overflow: "hidden",
          }}
        >
          <div
            className="text"
            style={{
              width: "100%",
              transform: "scale(1.7)",
              overflowX: "hidden",
              transformOrigin: "top left",
            }}
          >
            <HeatMap
              value={employeeAttendance}
              width="58%"
              startDate={new Date(new Date().getFullYear(), 0, 1)}
              endDate={new Date(new Date().getFullYear(), 11, 31)}
              legendCellSize={0}
              rectRender={(props, data) => {
                // Customize fill color based on status
                let fillColor;
                switch (data.status) {
                  case "Present":
                    fillColor = "green";
                    break;
                  case "Leave":
                    fillColor = "yellow";
                    break;
                  default:
                    fillColor = "#d6e2e9"; // Default color
                }
                const tooltipContent = `${data.date || ""} ${
                  data.status || ""
                }`;
                return (
                  <Tooltip placement="top" content={tooltipContent}>
                    <rect {...props} fill={fillColor} />
                  </Tooltip>
                );
              }}
            />

            {/* <CalendarHeatmap
              startDate={new Date(new Date().getFullYear(), 0, 1)}
              endDate={new Date(new Date().getFullYear(), 11, 31)}
              values={employeeAttendance}
              showWeekdayLabels
              classForValue={(value) => {
                if (!value) {
                  return `color-empty`;
                }
                return `color-filled`;
              }}
              classForMonth={(month) => {
                if (!month) {
                  return "";
                }
                return `class-for-month-${month}`;
              }}
              classForWeekday={(weekday) => {
                if (!weekday) {
                  return "";
                }
                return `class-for-weekday-${weekday}`;
              }}
            /> */}
          </div>
        </CardBody>
      </Card>
      <Card>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <CardTitle tag="h6" className="border-bottom p-3 my-2">
            <i className="bi bi-bell me-2"> </i>
            Employee Form
          </CardTitle>

          <p
            style={{
              margin: "20px",
              alignContent: "center",
              color: "red",
              fontSize: "20px",
              fontWeight: "500",
            }}
          >
            Leave Balance: {employeeData.leaveBalance}
          </p>
          <Button className="btn my-3" color="primary" onClick={openDocuments}>
            View Documents
          </Button>

          {/* <div style={{ display: "flex", alignItems: "center" }}>
            <p style={{ marginRight: "20px", alignContent: "center" }}>
              Leave Balance: {employeeData.leaveBalance}
            </p>
            <Button className="btn" color="primary" onClick={openDocuments}>
              View Documents
            </Button>
          </div> */}

          <Modal
            isOpen={showDocuments}
            onRequestClose={closeDocuments}
            contentLabel="View Documents"
          >
            <div className={styles.attendanceCalendarForm}>
              <p>cv link: {document.cvLink}</p>
              <p>
                cv Letter of Recommendation:{" "}
                {document.letterOfRecommendationLink}
              </p>
              <p>cv Cover Letter Link: {document.coverLetterLink}</p>
              <p>cv Citizenship Link: {document.citizenshipLink}</p>
              <p>Pan link: {document.panLink}</p>
              <div>
                <Link to={"edit-documents"}>
                  <button type="button" style={{ marginRight: "10px" }}>
                    Edit Documents
                  </button>
                </Link>
                <button type="button" onClick={closeDocuments}>
                  Cancel
                </button>
              </div>
            </div>
          </Modal>
        </div>

        <CardBody>
          {/* name */}
          <Form>
            <FormGroup>
              <Label for="name">Name</Label>
              <Input
                id="name"
                name="name"
                placeholder="Enter your name"
                type="text"
                value={employeeData.name}
                onChange={(n) => handleChange(n, "name")}
              />
            </FormGroup>
            <FormGroup>
              <Label for="dateOfBirth">Date of Birth</Label>
              <Input
                type="date"
                name="dateOfBirth"
                id="dateOfBirth"
                placeholder="date placeholder"
                value={employeeData.dateOfBirth}
                onChange={(d) => handleChange(d, "dateOfBirth")}
              />
            </FormGroup>

            <FormGroup>
              <Label for="genderSelect">Gender</Label>
              <Input
                id="genderSelect"
                name="genderSelect"
                type="select"
                value={employeeData.gender}
                onChange={(selectedOption) =>
                  handleChange(selectedOption, "gender")
                }
              >
                {genders.map((gender, index) => (
                  <option key={index} value={gender}>
                    {gender}
                  </option>
                ))}
              </Input>
            </FormGroup>
            {/* email */}
            <FormGroup>
              <Label for="email">Email</Label>
              <Input
                id="email_Address"
                name="email_Address"
                placeholder="Enter your email"
                type="email"
                value={employeeData.email_Address}
                onChange={(e) => handleChange(e, "email_Address")}
              />
            </FormGroup>
            {/* contact */}
            <FormGroup>
              <Label for="contact">Contact</Label>
              <Input
                id="phone_Number"
                name="phone_Number"
                placeholder="Enter your phone numer"
                type="number"
                value={employeeData.phone_Number}
                onChange={(c) => handleChange(c, "phone_Number")}
              />
            </FormGroup>
            {/* bank account number */}
            <FormGroup>
              <Label for="bank_acc_number">Bank Account Number</Label>
              <Input
                id="bank_acc_number"
                name="bank_acc_number"
                placeholder="Enter your account number"
                type="number"
                value={employeeData.bank_acc_number}
                onChange={(ban) => handleChange(ban, "bank_acc_number")}
              />
            </FormGroup>

            {/* salary */}
            <FormGroup>
              <Label for="salary">Salary</Label>
              <Input
                id="salary"
                name="salary"
                placeholder="Enter your salary"
                type="number"
                value={employeeData.salary}
                onChange={(sal) => handleChange(sal, "salary")}
              />
            </FormGroup>

            {/* emergency contact */}
            <FormGroup>
              <Label for="emergency_contact">Emergency Contact</Label>
              <Input
                id="emergency_contact"
                name="emergency_contact"
                placeholder="Enter your emergency contact"
                type="number"
                value={employeeData.emergency_contact}
                onChange={(ec) => handleChange(ec, "emergency_contact")}
              />
            </FormGroup>
            <FormGroup>
              <Label for="ImageFile">File</Label>
              <Input
                id="ImageFile"
                name="File"
                type="file"
                filename={employeeData.ImageFile}
                onChange={handleFileChange}
              />
              {/* <FormText>
              This is some placeholder block-level help text for the above
              input. It's a bit lighter and easily wraps to a new line.
            </FormText> */}
            </FormGroup>
          </Form>
          {/* submit botton */}
          {loading ? (
            <Button className="btn" color="primary" disabled>
              <Spinner size="sm" color="light" />
              Submitting...
            </Button>
          ) : (
            <Button className="btn" color="primary" onClick={handleSubmit}>
              Submit
            </Button>
          )}
          {/* <Button className="btn" color="primary" onClick={handleSubmit}>
            Submit
          </Button> */}
          <Link to="/employee-list">
            <Button className="btn" color="primary">
              Cancel
            </Button>
            <Button
              className="btn my-3"
              style={{ marginLeft: "60vw" }}
              color="danger"
              onClick={() => setIsPopupOpen(true)}
            >
              Delete
            </Button>
          </Link>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditEmployee;
