import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axios";

const Client = () => {
  const genders = ["Male", "Female", "Other"];
  const navigate = useNavigate();
  const [submitting, setLoading] = useState(false);

  const [clientData, setClientData] = useState({
    name: "",
    email: "",
    contact: "",
    gender: "male",
    emergency_contact: "",
    description: "",
  });

  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setClientData({
      ...clientData,
      [fieldName]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formDataToSend = new FormData();
    //form data object ko key value pair
    //key should match key in server.
    formDataToSend.append("id", 0);
    formDataToSend.append("name", clientData.name);
    formDataToSend.append("gender", clientData.gender);
    formDataToSend.append("phone_Number", clientData.contact);
    formDataToSend.append("email_Address", clientData.email);
    formDataToSend.append("emergency_Contact", clientData.emergency_contact);
    formDataToSend.append("treatment_History", clientData.description);
    const jsonObject = {};
    for (const [key, value] of formDataToSend) {
      jsonObject[key] = value;
    }
    console.log("form data : " + jsonObject);
    for (let [key, value] of formDataToSend.entries()) {
      console.log(key, value);
    }
    try {
      console.log(formDataToSend.data);
      const response = await axiosInstance.post(
        `/api/ClientInformation`,
        JSON.stringify(jsonObject),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setLoading(false);
      if (response.status === 201) {
        // Success
        alert("Data updated successfully!");
        navigate(-1);
      } else {
        // Display an error message
        alert("Failed to update data. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        <i className="bi bi-bell me-2"> </i>
        Client Form
      </CardTitle>
      <CardBody>
        {/* name */}
        <Form>
          <FormGroup>
            <Label for="name">Name</Label>
            <Input
              id="name"
              name="name"
              placeholder="Enter your name"
              type="text"
              value={clientData.name}
              onChange={(n) => handleChange(n, "name")}
            />
          </FormGroup>
          {/* email */}
          <FormGroup>
            <Label for="email">Email</Label>
            <Input
              id="email"
              name="email"
              placeholder="Enter your email"
              type="email"
              value={clientData.email}
              onChange={(e) => handleChange(e, "email")}
            />
          </FormGroup>
          {/* contact */}
          <FormGroup>
            <Label for="contact">Contact</Label>
            <Input
              id="contact"
              name="contact"
              placeholder="Enter your phone numer"
              type="number"
              value={clientData.contact}
              onChange={(c) => handleChange(c, "contact")}
            />
          </FormGroup>
          {/* gender */}
          <FormGroup>
            <Label for="genderSelect">Gender</Label>
            <Input
              id="genderSelect"
              name="genderSelect"
              type="select"
              value={clientData.gender}
              onChange={(selectedOption) =>
                handleChange(selectedOption, "gender")
              }
            >
              {genders.map((gender, index) => (
                <option key={index} value={gender}>
                  {gender}
                </option>
              ))}
            </Input>
          </FormGroup>
          {/* emergency contact */}
          <FormGroup>
            <Label for="emergency-contact">Emergency Contact</Label>
            <Input
              id="emergency-contact"
              name="emergency-contact"
              placeholder="Enter your emergency contact"
              type="number"
              value={clientData.emergency_contact}
              onChange={(ec) => handleChange(ec, "emergency_contact")}
            />
          </FormGroup>
          {/* //description */}
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              id="description"
              name="description"
              type="textarea"
              placeholder="Description goes here"
              value={clientData.description}
              onChange={(des) => handleChange(des, "description")}
            />
          </FormGroup>
        </Form>
        {/* submit botton */}
        {submitting ? (
          <Button className="btn" color="primary" disabled>
            <Spinner size="sm" color="light" />
            Submitting...
          </Button>
        ) : (
          <Button className="btn" color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        )}
        {/* <Button className="btn" color="primary" onClick={handleSubmit}>
          Submit
        </Button> */}
      </CardBody>
    </Card>
  );
};

export default Client;
