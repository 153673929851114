import React, { useState } from "react";
import "./ForgotPassword.css";
import logo from "../assets/images/logos/logo.png";
import { Spinner, Button } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const goBack = () => {
    navigate("/login");
  };
  const [email, setEmail] = useState("");
  const Submit = async (values) => {
    setLoading(true);

    try {
      const response = await fetch(
        `/api/Authentication/forgot-password?email=${email}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(email),
        }
      );
      if (response.ok) {
        setLoading(false);
        alert("Resetting successful! Check your email for confirmation.");
      } else {
        const data = await response.json();
        setLoading(false);
        alert("Something went wrong, please try again")
      }
    } catch (error) {
      setLoading(false);
      alert("Something went wrong, please try again")
    }
  };
  return (
    <div className="forgot-password-container">
      <img src={logo} alt="Logo" className="logo" />
      <FontAwesomeIcon
        icon={faArrowLeft}
        className="arrow-icon"
        onClick={goBack}
      />
      <div className="password-form-container">
        <h2>Forgot Password</h2>
        <p style={{ fontSize: "18px" }}>
          Please enter your email. A reset link will be sent to it.
        </p>
        <form className="forgot-password-form">
          <div>
            <label>Email:</label>
            <input
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              type="text"
              autoComplete="off"
              name="username"
              id="username"
              placeholder="Email"
            />
          </div>

          {/* <button type="submit">Login</button> */}
          {loading ? (
            <Button className="btn" color="primary" disabled onClick={Submit}>
              <Spinner size="sm" color="light" />
              Submitting...
            </Button>
          ) : (
            <button type="submit" onClick={Submit}>
              Submit
            </button>
          )}
        </form>
      </div>
    </div>
  );
};
export default ForgotPassword;
