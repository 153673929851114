import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../components/auth/AuthContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; // FontAwesomeIcon import
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import "./LoginForm.css"; // Import CSS file
import logo from "../assets/images/logos/logo.png"; // Import your logo image
import Cookies from "js-cookie";
import { Spinner, Button } from "reactstrap";
const LoginForm = () => {
  const { login } = useAuth();
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const goDash = () => {
    navigate("/dashboard");
  };
  const goCalender = () => {
    navigate("/employee-calender");
  };
  const goPassword = () => {
    navigate("/forgot-password");
  };
  const handleLogin = async (e) => {
    e.preventDefault();

    // Basic validation
    if (!username || !password) {
      setError("Please enter both username and password");
      return;
    }
    console.log("Username:", username);
    console.log("Password:", password);

    // Reset form fields and error message
    setUsername("");
    setPassword("");
    setError("");
    setLoading(true);
    try {
      const response = await login(username, password);
      console.log("LOGIN");
      if (response.status === 200) {
        console.log("Success");
        if (Cookies.get("userRole") == "Admin") {
          goDash();
        } else {
          goCalender();
        }
        setLoading(false);
      } else {
        const data = response.data;
        setMessage(`Login failed: ${data.message}`);
        alert(`Login failed: ${data.message}`);
        setLoading(false);
      }
    } catch (error) {
      setMessage("An error occurred during Login.");
      alert(`Login failed: Email or password wrong`);
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="login-container">
      <img src={logo} alt="Logo" className="logo" />
      <div className="login-form-container">
        <h2>Login</h2>
        {error && <p className="error-message">{error}</p>}
        <form onSubmit={handleLogin} className="login-form">
          <div>
            <label>Username:</label>
            <input
              type="text"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </div>
          <div>
            <label>Password:</label>
            <div className="password-input-container">
              <input
                type={showPassword ? "text" : "password"} // Conditionally set input type
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <FontAwesomeIcon
                icon={showPassword ? faEyeSlash : faEye} // Conditionally render eye icon
                onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                className="password-toggle-icon"
              />
            </div>
          </div>
          {loading ? (
            <Button className="btn" color="primary" disabled>
              <Spinner size="sm" color="light" />
              Logging In...
            </Button>
          ) : (
            <button className="submit" type="submit">
              Login
            </button>
          )}
        </form>
        <button className="forgot-button" onClick={goPassword}>
          Forgot Password?
        </button>
      </div>
    </div>
  );
};

export default LoginForm;
