import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Card,
  CardTitle,
  CardBody,
  Button,
  // CardSubtitle
} from "reactstrap";
import axiosInstance from "../../axios";
import { Link } from "react-router-dom";
import user1 from "../../assets/images/users/user1.jpg";
import user2 from "../../assets/images/users/user2.jpg";

const ClientList = () => {
  const [clientList, setClientList] = useState([]);
  const [empty , setEmpty] = useState(true);
  const [listType, setListType] = useState("active");
  useEffect(() => {
    let apiUrl;
    switch (listType) {
      case "active":
        apiUrl = "/api/ClientInformation/ActiveClients";
        break;
      case "inactive":
        apiUrl = "/api/ClientInformation/InActiveClients";
        break;
      case "upcoming":
        apiUrl = "/api/ClientInformation/UpComingClients";
        break;
      default:
        apiUrl = "/api/ClientInformation";
    }
    console.log(empty)

    axiosInstance
      .get(apiUrl)
      .then((response) => {
        setClientList(response.data);
        console.log(response.data.length)
        if(response.data.length<1){
          console.log("No data available")
          setEmpty(true)
        }
        else{
          console.log("Data already exist")
          setEmpty(false)
        }
      })
      .catch((error) => console.error("Error:", error));
  }, [listType , empty]); // Depend on listType so it re-fetches whenever listType changes

  return (
    <Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* table-1*/}
      {/* --------------------------------------------------------------------------------*/}
      <Col lg="12">
        {/* <div>
            {clientList.map((client, index) => (
                <div key={index}>
                // {/* Render your data here. For example, if each item is an object with a 'name' property: 
                <p>{client.name}</p>
                </div>
            ))}
        </div> */}
        <div>
          <Link to="/client">
            <Button className="btn me-4" color="primary">
              Add Client
            </Button>
          </Link>
          <div className="mt-2">
            {" "}
            {/* Container for spacing */}
            <Button
              className="btn me-4 my-4"
              color="info"
              style={listType==='active'?{background:'blue' , color:'white'}:{}}
              onClick={() => setListType("active")}
            >
              Active
            </Button>
            <Button
              className="btn me-4"
              color="secondary"
              style={listType==='inactive'?{background:'black' , color:'white'}:{}}
              onClick={() => setListType("inactive")}
            >
              Inactive
            </Button>
            <Button
              className="btn"
              color="warning"
              style={listType==='upcoming'?{background:'orange' , color:'white'}:{}}
              onClick={() => setListType("upcoming")}
            >
              Upcoming
            </Button>
          </div>
          <Card>
            <CardBody>
              <CardTitle tag="h5">Client Listing</CardTitle>
              {/* <CardSubtitle className="mb-2 text-muted" tag="h6">
                Overview of the projects
            </CardSubtitle> */}

           {empty && (<h3 style={{color:'red' , margin:'20px , 0px , 20px , 20px'}}>No any data</h3>)
           } 
            <Table
                className="no-wrap mt-3 align-middle"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    {/* <th>Client</th> */}
                    {/* <th>Project</th>

                    <th>Status</th>
                    <th>Weeks</th>
                    <th>Budget</th> */}
                  </tr>
                </thead>
                <tbody>
                  {clientList.map((client, index) => (
                    <tr key={index} className="border-top">
                      <td>
                        <Link
                          to={`${client.clientID}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="d-flex align-items-center p-2">
                            <img
                              src={client.gender === "male" ? user2 : user1}
                              className="rounded-circle"
                              alt="avatar"
                              width="45"
                              height="45"
                            />
                            <div className="ms-3">
                              <h6 className="mb-0">{client.name}</h6>
                              <span className="text-muted">{client.email}</span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      {/* <td>{tdata.project}</td> */}
                      {/* <td>
                        {tdata.status === "pending" ? (
                        <span className="p-2 bg-danger rounded-circle d-inline-block ms-3"></span>
                        ) : tdata.status === "holt" ? (
                        <span className="p-2 bg-warning rounded-circle d-inline-block ms-3"></span>
                        ) : (
                        <span className="p-2 bg-success rounded-circle d-inline-block ms-3"></span>
                        )}
                    </td> */}
                      {/* <td>{tdata.weeks}</td>
                    <td>{tdata.budget}</td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
              
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default ClientList;
