import React from 'react';
import { Button, Nav, NavItem } from 'reactstrap';
import Logo from './Logo';
import { Link, useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';

const navigation = [
  {
    title: 'Dashboard',
    href: 'dashboard',
    icon: 'bi bi-speedometer2',
  },
  {
    title: 'Calender',
    href: 'calender',
    icon: 'bi bi-bell',
  },
  {
    title: 'Employee Calender',
    href: 'employee-calender',
    icon: 'bi bi-bell',
  },
  {
    title: 'ClientList',
    href: 'client-list',
    icon: 'bi bi-list-ol',
  },
  {
    title: 'EmployeeList',
    href: 'employee-list',
    icon: 'bi bi-list-ol',
  },
  // {
  //   title: "Expired Membership",
  //   href: "expired-membership",
  //   icon: "bi bi-list-ol",
  // },
];

const Sidebar = () => {
  const showMobilemenu = () => {
    document.getElementById('sidebarArea').classList.toggle('showSidebar');
  };
  const userRole = Cookies.get('userRole');
  const location = useLocation();

  // Filter navigation items based on user role
  const filteredNavigation = navigation.filter((navItem) => {
    // If the user is Admin
    if (userRole === 'Admin') {
      return navItem;
    }
    // If the user is FrontDesk
    else if (userRole === 'FrontDesk') {
      return (
        navItem.title !== 'Employee Calender' && navItem.title !== 'Dashboard'
      );
    }
    // for employee
    else {
      return navItem.title === 'Employee Calender';
    }
  });
  return (
    <div className="p-3">
      <div className="d-flex align-items-center px-4">
        <Logo />
        <span className="ms-auto d-lg-none">
          <Button
            close
            size="sm"
            className="ms-auto d-lg-none"
            onClick={() => showMobilemenu()}
          ></Button>
        </span>
      </div>
      <div>
        <Nav vertical className="sidebarNav">
          {filteredNavigation.map((navItem, index) => (
            <NavItem key={index} className="sidenav-bg">
              <Link
                to={navItem.href}
                className={
                  location.pathname === navItem.href
                    ? 'text-primary nav-link py-3'
                    : 'nav-link text-secondary py-3'
                }
              >
                <i className={navItem.icon}></i>
                <span className="ms-3 d-inline-block">{navItem.title}</span>
              </Link>
            </NavItem>
          ))}
        </Nav>
      </div>
    </div>
  );
};

export default Sidebar;
