import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Table,
  Card,
  CardTitle,
  CardBody,
  Button,
  // CardSubtitle
} from "reactstrap";
import { Link } from "react-router-dom";
import user1 from "../../assets/images/users/user1.jpg";
import user2 from "../../assets/images/users/user2.jpg";
import user3 from "../../assets/images/users/user3.jpg";
import user4 from "../../assets/images/users/user4.jpg";
import user5 from "../../assets/images/users/user5.jpg";
import axiosInstance from "../../axios";

const EmployeeList = () => {
  const [employeeList, setEmployeetList] = useState([]);

  useEffect(() => {
    axiosInstance
      .get("/api/Employee")
      .then((response) => {
        console.log(response.data);
        setEmployeetList(response.data);
        // console.log(items[0]);// response.data contains the actual data
      })
      .catch((error) => console.error("Error:", error));
  }, []);

  return (
    <Row>
      {/* --------------------------------------------------------------------------------*/}
      {/* table-1*/}
      {/* --------------------------------------------------------------------------------*/}
      <Col lg="12">
        {/* <div>
            {clientList.map((client, index) => (
                <div key={index}>
                // {/* Render your data here. For example, if each item is an object with a 'name' property: 
                <p>{client.name}</p>
                </div>
            ))}
        </div> */}
        <div>
          <Link to="/add-employee">
            <Button className="btn my-3" color="primary">
              Add Employee
            </Button>
          </Link>
          <Card>
            <CardBody>
              <CardTitle tag="h5">Employee Listing</CardTitle>
              {/* <CardSubtitle className="mb-2 text-muted" tag="h6">
                Overview of the projects
            </CardSubtitle> */}

              <Table
                className="no-wrap mt-3 align-middle"
                responsive
                borderless
              >
                <thead>
                  <tr>
                    <th>Employee</th>
                    {/* <th>Project</th>

                    <th>Status</th>
                    <th>Weeks</th>
                    <th>Budget</th> */}
                  </tr>
                </thead>
                <tbody>
                  {employeeList.map((employee, index) => (
                    <tr key={index} className="border-top">
                      <td>
                        <Link
                          to={`${employee.employeeID}`}
                          style={{ textDecoration: "none" }}
                        >
                          <div className="d-flex align-items-center p-2">
                            <img
                              src={employee.gender === "Male" ? user2 : user1}
                              className="rounded-circle"
                              alt="avatar"
                              width="45"
                              height="45"
                            />
                            <div className="ms-3">
                              <h6 className="mb-0">{employee.name}</h6>
                              <span className="text-muted">
                                {employee.email}
                              </span>
                            </div>
                          </div>
                        </Link>
                      </td>
                      {/* <td>{tdata.project}</td> */}
                      {/* <td>
                        {tdata.status === "pending" ? (
                        <span className="p-2 bg-danger rounded-circle d-inline-block ms-3"></span>
                        ) : tdata.status === "holt" ? (
                        <span className="p-2 bg-warning rounded-circle d-inline-block ms-3"></span>
                        ) : (
                        <span className="p-2 bg-success rounded-circle d-inline-block ms-3"></span>
                        )}
                    </td> */}
                      {/* <td>{tdata.weeks}</td>
                    <td>{tdata.budget}</td> */}
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
          </Card>
        </div>
      </Col>
    </Row>
  );
};

export default EmployeeList;
