import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Spinner,
  Modal,
} from "reactstrap";
// import bg3 from "../../../assets/images/bg/bg3.jpg";
import axiosInstance from "../../axios";
import "./client.css";
import { useParams, Link, useNavigate } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import HeatMap from "@uiw/react-heat-map";
import "./calendar-heatmap.css";
import { each } from "jquery";
import Tooltip from "@uiw/react-tooltip";
import EditSingleMembership from "./EditSIngleMembership";

const EditClient = () => {
  const [submitting, setLoading] = useState(false);
  const genders = ["Male", "Female", "Other"];
  // const [selectedOption, setSelectedOption] = useState('');
  //const countries = CountryList().getData();
  //const cookieValue = Cookies.get("userId");
  const { clientId } = useParams();
  // console.log(url+"****************");
  // const clientId = url.split("/").pop();
  // console.log(clientId+"****************");
  // const clientId = 0;

  const [clientData, setClientData] = useState({
    name: "",
    email: "",
    contact: "",
    gender: "male",
    emergency_contact: "",
    description: "",
    // UserId: cookieValue,
    // country: "",
    // streetName: "",
    // city: "",
    // province: "",
    // zipCode: "",
  });
  const [sessionDates, setSessionDates] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date().getMonth());
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  const [showSingleMembership, setSingleMembershipFlag] = useState(true);
  const [showGroupMembership, setGroupMembershipFlag] = useState(false);

  const [notFoundFlag, setNotFoundFlag] = useState(0);
  useEffect(() => {
    const fetchData = async () => {
      console.log("fetch client data");
      try {
        const response = await axiosInstance.get(
          `/api/ClientInformation/${clientId}`
          // `/api/AddressModels/${cookieValue}`
        );
        // console.log("get : " + response.data);
        setClientData({
          name: response.data.name,
          email: response.data.email_Address,
          contact: response.data.phone_Number,
          gender: response.data.gender,
          emergency_contact: response.data.emergency_Contact,
          description: response.data.treatment_History,
          // country: response.data.country,
          // streetName: response.data.streetName,
          // city: response.data.city,
          // state: response.data.state,
          // zipCode: response.data.zipCode,
        });
        setNotFoundFlag(0);
      } catch (error) {
        //console.error("Error:", error);
        // Handle error
        if (error.response && error.response.status === 404) {
          // Set the notFoundFlag to 1 if it's a 404 error
          setNotFoundFlag(1);
        } else {
          // Handle other errors
          setNotFoundFlag(0);
        }
      }
    };

    fetchData();
  }, [clientId]);
  useEffect(() => {
    // fetchData and fetchSessionData useEffects remain the same
  }, [clientId]);

  // Increment and decrement month
  const nextMonth = () => {
    if (currentMonth === 11) {
      setCurrentYear(currentYear + 1);
      setCurrentMonth(0);
    } else {
      setCurrentMonth(currentMonth + 1);
    }
  };

  const prevMonth = () => {
    if (currentMonth === 0) {
      setCurrentYear(currentYear - 1);
      setCurrentMonth(11);
    } else {
      setCurrentMonth(currentMonth - 1);
    }
  };

  // Filter sessionDates for the current month
  const datesForCurrentMonth = sessionDates.filter((date) => {
    const dateObj = new Date(date.date);
    return (
      dateObj.getMonth() === currentMonth &&
      dateObj.getFullYear() === currentYear
    );
  });

  // Add handleSubmit, handleChange, and other unchanged functions here

  //session data

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await axiosInstance.get(
          `/api/ClientInformation/SessionDates/${clientId}`
        );
        if (response.status == 200) {
          console.log("session success : ", response.data);
          // setSessionDates(response.data);
          const datesList = [];
          for (const date of response.data) {
            const formattedDate = calcFormattedDate(date);
            const dateObj = {
              date: formattedDate,
              // value: 4,
              count: 30,
            };
            datesList.push(dateObj);
            console.log("dateObj", dateObj.date);
            // console.log(dateObj.value);
            console.log(dateObj.count);
            // console.log("loop data: ", date);
            console.log("loop data: ", formattedDate);
          }
          console.log(datesList.length);
          console.log("daTESSSSSSSS");
          setSessionDates(datesList);
        } else {
          console.log("failure: ", response.data);
        }
      } catch (error) {
        console.log("Error: ", error);
      }
    };

    fetchSessionData();
  }, []);

  //convert date
  const calcFormattedDate = (dateString) => {
    const dateObj = new Date(dateString);
    const year = dateObj.getFullYear();
    const month = String(dateObj.getMonth() + 1).padStart(2, "0");
    const day = String(dateObj.getDate()).padStart(2, "0");

    // return `${year}-${month}-${day}`;
    return `${year}/${month}/${day}`;
  };

  const [singleMembershipList, setSingleMembershipData] = useState([]);
  const fetchSingleMembershipData = async () => {
    setActive("OnetoOne");
    setSingleMembershipFlag(true);
    setGroupMembershipFlag(false);

    // useEffect(() => {
    // const fetchSingleMembershipData = async () => {
    console.log("fetch One to one membership data");
    try {
      const response = await axiosInstance.get(
        `/api/ClientInformation/SingleMembership/${clientId}`
      );

      if (response.status === 200) {
        setSingleMembershipData(response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log("error : ", error);
    }
    // };

    // fetchSingleMembershipData();
    // };
    // []);
  };

  const [groupMembershipList, setGroupMembershipData] = useState([]);
  const [active, setActive] = useState("OnetoOne");
  const fetchGroupMembershipData = async () => {
    setActive("groupmember");
    setSingleMembershipFlag(false);
    setGroupMembershipFlag(true);

    console.log("fetch group membership data");
    try {
      const response = await axiosInstance.get(
        `/api/ClientInformation/GroupMembership/${clientId}`
      );

      if (response.status == 200) {
        setGroupMembershipData(response.data);
      } else {
        console.log(response.data);
      }
    } catch (error) {
      console.log("error : ", error);
    }
  };

  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    setClientData({
      ...clientData,
      [fieldName]: newValue,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formDataToSend = new FormData();
    //form data object ko key value pair
    //key should match key in server.
    // formDataToSend.append('id', clientId)
    formDataToSend.append("name", clientData.name);
    formDataToSend.append("gender", clientData.gender);
    formDataToSend.append("phone_Number", clientData.contact);
    formDataToSend.append("email_Address", clientData.email);
    formDataToSend.append("emergency_Contact", clientData.emergency_contact);
    formDataToSend.append("treatment_History", clientData.description);
    // formDataToSend.append("userId", cookieValue);
    // formDataToSend.append("country", formData.country);
    // formDataToSend.append("streetName", formData.streetName);
    // formDataToSend.append("city", formData.city);
    // formDataToSend.append("state", formData.state);
    // formDataToSend.append("zipCode", formData.zipCode);
    console.log("form data : " + formDataToSend.get("name"));
    console.log(clientId);
    formDataToSend.forEach((value, key) => {
      console.log(`${key}: ${value}`);
    });
    if (notFoundFlag === 1) {
      // personalInfo is empty, data hasn't been fetched, handle accordingly
      console.log("DATA IS EMPTY");
      try {
        console.log(formDataToSend.data);
        await axiosInstance.post(`/api/ClientInformation`, formDataToSend, {
          headers: {
            "Content-Type": "application/json",
          },
        });
      } catch (error) {
      } finally {
        setLoading(false);
      }
      return;
    }
    try {
      const response = await axiosInstance.put(
        `/api/ClientInformation/${clientId}`,
        formDataToSend,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      ); // Assuming a PUT request for updating
      console.log("Response:", response);
      if (response.status === 200) {
        // Success
        alert("Data updated successfully!");
        navigate(-1);
      } else {
        // Display an error message
        alert("Failed to update data. Please try again.");
      }
      // Add any additional logic you need after a successful submission
    } catch (error) {
      console.error("Error:", error);
      // Handle error
    } finally {
      setLoading(false);
    }
  };

  const navigate = useNavigate();
  const handleAddMembership = () => {
    navigate("./add-membership", { state: { clientName: clientData.name } });
  };

  //mouse hover
  // State variables
  // const [tooltipContent, setTooltipContent] = useState(null);

  // // Handle mouse hover events
  // const handleMouseOver = (event, value) => {
  //   if (value) {
  //     const boundingRect = event.target.getBoundingClientRect();
  //     const tooltipContent = value.date || value; // If value has a date property, use it, otherwise use the entire value
  //     setTooltipContent({
  //       content: tooltipContent,
  //       top: boundingRect.top + window.scrollY + 10,
  //       left: boundingRect.left + window.scrollX + 10,
  //     });
  //   }
  // };

  // // Handle mouse leave event
  // const handleMouseLeave = () => {
  //   setTooltipContent(null);
  // };

  // const value = [
  //   { date: "2024/01/11", count: 2 },
  //   { date: "2024/04/12", count: 2 },
  //   { date: "2024/05/01", count: 5 },
  //   { date: "2024/05/02", count: 5 },
  //   { date: "2024/05/03", count: 1 },
  //   { date: "2024/05/04", count: 11 },
  //   { date: "2024/05/08", count: 32 },
  // ];

  //date formatter
  const formatDate = (inputDate) => {
    const date = new Date(inputDate);

    // Get day, month, and year
    const day = date.getDate();
    const month = date.toLocaleString("default", { month: "long" });
    const year = date.getFullYear();

    // Add suffix to day
    let dayWithSuffix;
    switch (day) {
      case 1:
      case 21:
      case 31:
        dayWithSuffix = day + "st";
        break;
      case 2:
      case 22:
        dayWithSuffix = day + "nd";
        break;
      case 3:
      case 23:
        dayWithSuffix = day + "rd";
        break;
      default:
        dayWithSuffix = day + "th";
    }

    // Construct the formatted date
    const formattedDate = `${dayWithSuffix} ${month} ${year}`;
    return formattedDate;
  };

  // const [editSingleMembership, setEditSingleMembershipFlag] = useState(false);

  // const openEditSingleMemebershipModal = () => {
  //   setEditSingleMembershipFlag(true);
  // };

  // const closeEditSingleMembershipModal = () => {
  //   setEditSingleMembershipFlag(false);
  // };

  //navigate to edit membership
  const handleEditMembership = (singleMembershipData) => {
    navigate(
      `/client-list/${clientId}/edit-single-membership/${singleMembershipData.membershipID}`,
      {
        state: {
          singleMembershipData: singleMembershipData,
          clientName: clientData.name,
          clientId: clientId,
        },
      }
    );
  };

  //navigate to edit group membership
  const handleEditGroupMembership = (groupMembershipData) => {
    navigate(
      `/client-list/${clientId}/edit-group-membership/${groupMembershipData.membershipID}`,
      {
        state: {
          groupMembershipData: groupMembershipData,
          clientName: clientData.name,
          clientId: clientId,
        },
      }
    );
  };
  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const ConfirmationPopup = ({ isOpen, onClose, onConfirm }) => {
    if (!isOpen) return null;

    return (
      <div className="popup-overlay">
        <div className="popup">
          <p>Are you sure you want to delete the record?</p>
          <div className="button-container">
            <button onClick={onConfirm}>Yes</button>
            <button onClick={onClose}>No</button>
          </div>
        </div>
      </div>
    );
  };
  const handleDelete = async (e) => {
    // Delete record logic goes here
    console.log("Record deleted");
    try {
      const response = await axiosInstance.delete(
        `/api/ClientInformation/${clientId}`
      );

      if (response.status === 200) {
        alert("Deleted Successfully");
        console.log("working!!!!!!!!!!");
        navigate("/client-list");
      } else {
        console.log(response);
      }
    } catch (error) {
      console.log("error : ", error);
    }
    setIsPopupOpen(false);
  };

  return (
    <div>
      <Card>
        <CardTitle tag="h6" className="border-bottom p-3 mb-0">
          Calendar
        </CardTitle>

        <CardBody>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <div style={{ marginBottom: "1rem" }}>
              <Button onClick={prevMonth} style={{ marginRight: "0.5rem" }}>
                Prev
              </Button>
              <Button onClick={nextMonth}>Next</Button>
            </div>
            <div
              style={{
                overflowX: "auto",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <HeatMap
                value={datesForCurrentMonth}
                startDate={new Date(currentYear, currentMonth, 1)}
                endDate={new Date(currentYear, currentMonth + 1, 0)}
                rectSize={40}
                rectRender={(props, data) => (
                  <Tooltip placement="top" content={`${data.date || 0}`}>
                    <rect {...props} />
                  </Tooltip>
                )}
                // Custom styles to ensure cells and text are appropriately sized
                style={{
                  fontSize: "0.8rem",
                  width: "30%",
                  height: "350px",
                  alignItems: "center",
                }}
              />
            </div>
          </div>
        </CardBody>
      </Card>
      <Card>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <CardTitle tag="h6" className="border-bottom p-3 mb-0">
            <i className="bi bi-bell me-2"> </i>
            Client Form
          </CardTitle>
          {/* <Link
            to={{
              pathname: "./add-membership",
              state: { clientName: clientData.name },
            }}
          > */}
          <Button
            className="btn my-3"
            color="primary"
            onClick={() => setIsPopupOpen(true)}
          >
            Delete
          </Button>
          <ConfirmationPopup
            isOpen={isPopupOpen}
            onClose={() => setIsPopupOpen(false)}
            onConfirm={handleDelete}
          />
          <Button
            className="btn my-3"
            color="primary"
            onClick={handleAddMembership}
          >
            Add Membership
          </Button>
          {/* </Link> */}
        </div>
        <CardBody style={{ display: "flex" }}>
          <div
            style={{
              flex: 1,
              marginRight: "0.5rem",
              paddingRight: "0.5rem",
              borderRight: "1px solid #ccc",
            }}
          >
            {/* name */}
            <Form>
              <FormGroup>
                <Label for="name">Name</Label>
                <Input
                  id="name"
                  name="name"
                  placeholder="Enter your name"
                  type="text"
                  value={clientData.name}
                  onChange={(n) => handleChange(n, "name")}
                />
              </FormGroup>
              {/* email */}
              <FormGroup>
                <Label for="email">Email</Label>
                <Input
                  id="email"
                  name="email"
                  placeholder="Enter your email"
                  type="email"
                  value={clientData.email}
                  onChange={(e) => handleChange(e, "email")}
                />
              </FormGroup>
              {/* contact */}
              <FormGroup>
                <Label for="contact">Contact</Label>
                <Input
                  id="contact"
                  name="contact"
                  placeholder="Enter your phone numer"
                  type="number"
                  value={clientData.contact}
                  onChange={(c) => handleChange(c, "contact")}
                />
              </FormGroup>
              {/* gender */}
              <FormGroup>
                <Label for="genderSelect">Gender</Label>
                <Input
                  id="genderSelect"
                  name="genderSelect"
                  type="select"
                  value={clientData.gender}
                  onChange={(selectedOption) =>
                    handleChange(selectedOption, "gender")
                  }
                >
                  {genders.map((gender, index) => (
                    <option key={index} value={gender}>
                      {gender}
                    </option>
                  ))}
                </Input>
              </FormGroup>
              {/* emergency contact */}
              <FormGroup>
                <Label for="emergency-contact">Emergency Contact</Label>
                <Input
                  id="emergency-contact"
                  name="emergency-contact"
                  placeholder="Enter your emergency contact"
                  type="number"
                  value={clientData.emergency_contact}
                  onChange={(ec) => handleChange(ec, "emergency_contact")}
                />
              </FormGroup>
              {/* //description */}
              <FormGroup>
                <Label for="description">Treatment History</Label>
                <Input
                  id="description"
                  name="description"
                  type="textarea"
                  placeholder="Description goes here"
                  value={clientData.description}
                  onChange={(des) => handleChange(des, "description")}
                />
              </FormGroup>
            </Form>
            {/* submit botton */}
            {submitting ? (
              <Button className="btn" color="primary" disabled>
                <Spinner size="sm" color="light" />
                Submitting...
              </Button>
            ) : (
              <Button className="btn" color="primary" onClick={handleSubmit}>
                Submit
              </Button>
            )}
            {/* <Button className="btn" color="primary" onClick={handleSubmit}>
              Submit
            </Button> */}
          </div>

          <div style={{ flex: 1, marginLeft: "0.5rem", paddingLeft: "0.5rem" }}>
            <h2 style={{ marginRight: "auto" }}>Membership</h2>
            <div
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                marginBottom: "1rem",
              }}
            >
              <Button
                className="btn"
                color="primary"
                onClick={fetchSingleMembershipData}
                style={
                  active === "OnetoOne"
                    ? {
                        backgroundColor: "blue",
                        color: "white",
                        transition: "1s ease",
                      }
                    : {}
                }
              >
                One to one membership
              </Button>
              <Button
                className="btn"
                color="primary"
                onClick={fetchGroupMembershipData}
                style={
                  active === "groupmember"
                    ? {
                        backgroundColor: "blue",
                        color: "white",
                        transition: "1s ease",
                      }
                    : {}
                }
              >
                Group Membership
              </Button>
            </div>
            {showSingleMembership ? (
              <ul>
                {singleMembershipList.map((membership, index) => (
                  <Card
                    key={index}
                    className="shadow p-3 mb-5 bg-white rounded"
                  >
                    <CardBody>
                      <Button
                        className="btn"
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() =>
                          handleEditMembership(singleMembershipList[index])
                        }
                      >
                        Edit
                      </Button>

                      <p>
                        Membership Name :{" "}
                        {membership.o2Onerelationship != null
                          ? membership.o2Onerelationship.membershipName
                          : "null"}
                      </p>
                      <p>Membership Cost : {membership.membershipCost}</p>
                      <p>
                        Purchase Date : {formatDate(membership.purchaseDate)}
                      </p>
                      <p>
                        Session Count :{" "}
                        {membership.o2Onerelationship != null
                          ? membership.o2Onerelationship.sessionCount
                          : "null"}
                      </p>
                    </CardBody>
                    {/* <Modal
                      isOpen={openEditSingleMemebershipModal}
                      toggle={closeEditSingleMembershipModal}
                    >
                      <EditSingleMembership
                        clientId={clientId}
                        singleMembershipData={singleMembershipList[index]}
                        clientName={clientData.name}
                      />
                    </Modal> */}
                  </Card>
                ))}
              </ul>
            ) : null}

            {showGroupMembership ? (
              <ul>
                {groupMembershipList.map((membership, index) => (
                  <Card
                    key={index}
                    className="shadow p-3 mb-5 bg-white rounded"
                  >
                    <CardBody>
                      <Button
                        className="btn"
                        color="primary"
                        style={{ float: "right" }}
                        onClick={() =>
                          handleEditGroupMembership(groupMembershipList[index])
                        }
                      >
                        Edit
                      </Button>
                      <p>
                        Membership Name :{" "}
                        {membership.o2Manyrelationship != null
                          ? membership.o2Manyrelationship.membershipName
                          : "null"}
                      </p>
                      <p>Membership Cost : {membership.membershipCost}</p>
                      <p>
                        Purchase Date : {formatDate(membership.purchaseDate)}
                      </p>
                    </CardBody>
                  </Card>
                ))}
              </ul>
            ) : null}
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default EditClient;
