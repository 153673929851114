import React, { useEffect, useState } from "react";
import {
  Card,
  Form,
  Input,
  CardBody,
  CardTitle,
  FormGroup,
  Label,
  Button,
  Spinner,
} from "reactstrap";
import axiosInstance from "../../axios";
import { useLocation } from "react-router-dom";

const EditGroupMembership = () => {
  const location = useLocation();
  const additionalData = location.state?.groupMembershipData;
  const clientName = location.state?.clientName;
  const clientId = location.state?.clientId;

  const [groupMembershipData, setGroupMembershipData] = useState({
    membershipID: additionalData.membershipID,
    membershipCost: additionalData.membershipCost,
    purchaseDate: additionalData.purchaseDate,
    membershipName: additionalData.o2Manyrelationship.membershipName,
    startDate: additionalData.o2Manyrelationship.startDate,
    sessionDuration: additionalData.o2Manyrelationship.sessionDuration,
    endDate: additionalData.o2Manyrelationship.endDate,
  });
  const [formErrors, setFormErrors] = useState({
    cost: false,
    membershipName: false,
    sessionDuration: false,
    startDate: false,
    endDate: false,
  });
  const [startDate, setStartDate] = useState("");

  const [endDate, setEndDate] = useState("");
  const [displayEndDate, setDisplayEndDate] = useState("");

  //code to display
  const datetoDisplay = (date) => {
    console.log("date to display ::%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%");
    console.log("Start date ho hai: ", date);
    const formattedDate = new Date(date);
    console.log("Formatted date: ", formattedDate);
    const datetoDisplay =
      formattedDate.getFullYear().toString() +
      "-" +
      (formattedDate.getMonth() + 1).toString().padStart(2, "0") +
      "-" +
      formattedDate.getDate().toString().padStart(2, "0");

    // console.log("date to display: ", datetoDisplay);
    return datetoDisplay;
  };

  const membership = ["Functional Group", "Strength and Conditioning", "Rehab"];
  const sessions = [
    "1 month",
    "2 months",
    "3 months",
    "4 months",
    "5 months",
    "6 months",
    "7 months",
    "8 months",
    "9 months",
    "10 months",
    "11 months",
    "12 months",
  ];

  const membershipName = ["Performance Training", "Treatment Sessions"];

  const [therapistNames, setTherapistNames] = useState([]);
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    console.log("Edit Single Membership Page%%%%%%%%%");
    const fetchTherapistNames = async () => {
      try {
        const response = await axiosInstance.get("/api/Employee/TherapistName");
        setTherapistNames(response.data); // Assuming the API returns an array of names
      } catch (error) {
        console.error("Failed to fetch therapist names:", error);
      }
    };

    fetchTherapistNames();
  }, []);

  const handleChange = (input, fieldName) => {
    const newValue = input.target ? input.target.value : input.label;
    console.log("New Value", newValue);
    setGroupMembershipData({
      ...groupMembershipData,
      [fieldName]: newValue,
    });
    if (fieldName === "startDate") {
      console.log("Date picker to date format", input.target.value);
      console.log("start date ko value", groupMembershipData.startDate);
      setGroupMembershipData({
        ...groupMembershipData,
        startDate: input.target.value,
        endDate: calculateEndDate(
          groupMembershipData.sessionDuration,
          newValue
        ),
      });
      // setStartDate(input.target.value);
      // calculateEndDate(groupMembershipData.sessionDuration); // Update the start date based on user selection
    }
    if (fieldName === "sessionDuration") {
      // Calculate end date based on session duration
      // const calculatedEndDate =
      // calculateEndDate(newValue);
      // setEndDate(calculatedEndDate);
      setGroupMembershipData({
        ...groupMembershipData,
        sessionDuration: newValue,
        endDate: calculateEndDate(newValue, groupMembershipData.startDate),
      });
    }
  };

  //method to calculate end date
  const calculateEndDate = (option, startDate) => {
    console.log("calculate end date");
    // const today = new Date(groupMembershipData.startDate);
    const today = new Date(startDate);
    const endDate = new Date(today);
    switch (option) {
      case "1 month":
        endDate.setMonth(today.getMonth() + 1);
        break;
      case "2 months":
        endDate.setMonth(today.getMonth() + 2);
        break;
      case "3 months":
        endDate.setMonth(today.getMonth() + 3);
        break;
      case "4 months":
        endDate.setMonth(today.getMonth() + 4);
        break;
      case "5 months":
        endDate.setMonth(today.getMonth() + 5);
        break;
      case "6 months":
        endDate.setMonth(today.getMonth() + 6);
        break;
      case "7 months":
        endDate.setMonth(today.getMonth() + 7);
        break;
      case "8 months":
        endDate.setMonth(today.getMonth() + 8);
        break;
      case "9 months":
        endDate.setMonth(today.getMonth() + 9);
        break;
      case "10 months":
        endDate.setMonth(today.getMonth() + 10);
        break;
      case "11 months":
        endDate.setMonth(today.getMonth() + 11);
        break;
      case "12 months":
        endDate.setMonth(today.getMonth() + 12);
        break;
      default:
        return null;
    }

    // console.log("calculate garera ayesi ko date", endDate);

    // For display: MM/DD/YYYY
    const displayFormattedEndDate = datetoDisplay(endDate);
    // console.log("end date calculated : ", displayFormattedEndDate);
    return displayFormattedEndDate;
    // setGroupMembershipData({
    //   ...groupMembershipData,
    //   ["sessionDuration"]: option,
    //   ["endDate"]: displayFormattedEndDate,
    // });
  };

  //date formatter
  const dateFormatter = (dateTimeString) => {
    const dateTime = new Date(dateTimeString);
    dateTime.setHours(dateTime.getHours() + 1);
    const year = dateTime.getFullYear();
    const month = String(dateTime.getMonth() + 1).padStart(2, "0");
    const day = String(dateTime.getDate()).padStart(2, "0");
    const hours = String(dateTime.getHours()).padStart(2, "0");
    const minutes = String(dateTime.getMinutes()).padStart(2, "0");
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}`;
    return formattedDateTime;
  };

  const calculateDateToSend = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");
    const milliseconds = String(date.getMilliseconds()).padStart(3, "0");
    const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}Z`;
    console.log("CALCULATEDS DATE TO SEND");
    console.log(formattedDate);
    return formattedDate;
  };

  //put method for single membership
  const putGroupMembershipData = async () => {
    let newFormErrors = { ...formErrors };
    if (
      groupMembershipData.membershipCost === "0" ||
      groupMembershipData.membershipCost === ""
    ) {
      newFormErrors.cost = true;
    } else {
      newFormErrors.cost = false;
    }

    if (groupMembershipData.membershipName === "") {
      newFormErrors.membershipName = true;
    } else {
      newFormErrors.membershipName = false;
    }
    if (groupMembershipData.sessionDuration === "") {
      newFormErrors.sessionDuration = true;
    } else {
      newFormErrors.sessionDuration = false;
    }
    if (groupMembershipData.startDate === "") {
      newFormErrors.startDate = true;
    } else {
      newFormErrors.startDate = false;
    }
    setFormErrors(newFormErrors);
    if (
      groupMembershipData.membershipCost === "0" ||
      groupMembershipData.membershipCost === "" ||
      groupMembershipData.membershipName === "" ||
      groupMembershipData.sessionDuration === "" ||
      groupMembershipData.startDate === ""
    ) {
      alert("Please fill the required fields.");
      return;
    } else {
      setLoading(true);
      const jsonObject = {
        membershipCost: groupMembershipData.membershipCost,
        purchaseDate: groupMembershipData.purchaseDate,
        addO2MRelationshipDTO: {
          membershipName: groupMembershipData.membershipName,
          sessionDuration: groupMembershipData.sessionDuration,
          startDate: calculateDateToSend(groupMembershipData.startDate),
          endDate: calculateDateToSend(groupMembershipData.endDate),
        },
      };
      try {
        const response = await axiosInstance.put(
          `/api/ClientInformation/GroupMembership/ClientID=${clientId}/MembershipID=${additionalData.membershipID}/`,
          jsonObject,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );
        if (response.status == 200) {
          alert("Membership updated successfully");
        } else {
          console.log("SErver Not found", response.data);
        }
      } catch (error) {
        if (error.response && error.response.status === 404) {
          console.log("Not found", error);
        } else {
          console.log("Error : ", error);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Card>
      <CardTitle tag="h6" className="border-bottom p-3 mb-0">
        Add Group Membership
      </CardTitle>
      <CardBody>
        <Form>
          {/* Membership Cost  */}
          <FormGroup>
            <Label for="cost">Membership Cost</Label>
            <Input
              id="cost"
              name="membershipCost"
              // placeholder="Membership Cost"
              type="text"
              value={groupMembershipData.membershipCost}
              onChange={(cost) => handleChange(cost, "membershipCost")}
              style={{
                border: formErrors.cost ? "1px solid red" : "1px solid #ccc",
              }}
            />
          </FormGroup>
          {/* Membership Name */}
          <FormGroup>
            <Label for="name">Membership Name </Label>
            <Input
              id="name"
              name="membership-name"
              type="select"
              value={groupMembershipData.membershipName}
              onChange={(selectedOption) =>
                handleChange(selectedOption, "membershipName")
              }
              style={{
                border: formErrors.membershipName
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}

              // onChange={()}
            >
              {membership.map((gender, index) => (
                <option key={index} value={gender}>
                  {gender}
                </option>
              ))}
            </Input>
          </FormGroup>
          {/* Start Date */}
          <FormGroup>
            <Label for="start-date">Start Date</Label>
            <Input
              id="start-date"
              name="start-date"
              type="date" // Changed to "date" for a date picker
              value={datetoDisplay(groupMembershipData.startDate)} // Controlled component with startDate state
              onChange={(date) => handleChange(date, "startDate")} // Handle changes
              style={{
                border: formErrors.startDate
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            />
          </FormGroup>

          {/* Session Count */}
          <FormGroup>
            <Label for="session-duration">Session Duration</Label>
            <Input
              id="session-duration"
              name="session-duration"
              placeholder="Select Session Duration"
              type="select"
              value={groupMembershipData.sessionDuration}
              onChange={(sc) => handleChange(sc, "sessionDuration")}
              style={{
                border: formErrors.sessionDuration
                  ? "1px solid red"
                  : "1px solid #ccc",
              }}
            >
              {sessions.map((session, index) => (
                <option key={index} value={session}>
                  {session}
                </option>
              ))}
            </Input>
          </FormGroup>

          {/* End Date */}
          <FormGroup>
            <Label for="end-date">End Date</Label>
            <Input
              id="end-date"
              name="end-date"
              type="date"
              value={groupMembershipData.endDate}
              readOnly
              // onChange={()}
            />
          </FormGroup>
        </Form>
        {/* submit botton */}
        {isLoading ? (
          <Button className="btn" color="primary" disabled>
            <Spinner size="sm" color="light" />
            Submitting...
          </Button>
        ) : (
          <Button
            className="btn"
            color="primary"
            onClick={putGroupMembershipData}
          >
            Submit
          </Button>
        )}
        {/* <Button className="btn" color="primary" onClick={handleSubmit}>
          Submit
        </Button> */}
      </CardBody>
    </Card>
  );
};
export default EditGroupMembership;
