import { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import styles from "./CalendarComponent.module.css";
import Modal from "react-modal";
import axiosInstance from "../../axios";
import "./Calendar.css";
Modal.setAppElement("#root");

const CalendarComponent = () => {
  const [showModal, setShowModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [therapistNames, setTherapistNames] = useState([]);
  const [clickedEventId, setClickedEventId] = useState(null);
  const [formData, setFormData] = useState({
    id: 0,
    doctorName: "",
    clientName: "",
    startTime: "",
    endTime: "",
    description: "",
  });
  useEffect(() => {
    const fetchTherapistNames = async () => {
      try {
        const response = await axiosInstance.get("/api/Employee/TherapistName");
        setTherapistNames(response.data); // Assuming the API returns an array of names
      } catch (error) {
        console.error("Failed to fetch therapist names:", error);
      }
    };

    fetchTherapistNames();
  }, []); // Empty dependency array means this effect runs once on mount

  const handleDateSelect = (selectInfo) => {
    const start = formatForDisplay(selectInfo.start);
    console.log("Hadnle date select L+++++++++++++", selectInfo.start);
    const end = formatForDisplay(
      new Date(selectInfo.start.getTime() + 60 * 60 * 1000)
    ); // Adds 1 hour to the start time

    // Update form data with these times
    setFormData({
      ...formData,
      doctorName: "", // Keep or reset other fields as needed
      clientName: "",
      startTime: start,
      endTime: end,
      description: "",
    });
    setShowModal(true);
    setEditMode(false);
    setClickedEventId(null);
  };
  function convertToISOString(dateString) {
    const date = new Date(dateString);
    console.log("DATE");
    console.log(date);
    console.log(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.000Z`;
  }
  function updateStartAndEndTime(formData) {
    const { startTime, endTime } = formData;

    const startDate = new Date(startTime);
    const endDate = new Date(endTime);

    // Convert start and end time to ISO string format
    const updatedStartTime = convertToISOString(startDate);
    const updatedEndTime = convertToISOString(endDate);

    // Return the updated formData object with new start and end times
    return {
      ...formData,
      startTime: updatedStartTime,
      endTime: updatedEndTime,
    };
  }

  // Function to format date for display
  function formatForDisplay(dateString) {
    const date = new Date(dateString);

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }
  const handleEventClick = (clickInfo) => {
    console.log("handleEventClick called");
    console.log("Clicked Event Object:", clickInfo.event);

    const { clientName, description } = clickInfo.event.extendedProps;
    const id = clickInfo.event.id;
    console.log(clickInfo.event.start);
    const doctorName = clickInfo.event.title;
    const start = formatForDisplay(clickInfo.event.start);
    const end = formatForDisplay(clickInfo.event.end);
    console.log("Clicked Event ID:", id);
    console.log("Clicked Event Data:", clickInfo.event.extendedProps);
    console.log("Clicked Event Info:", clickInfo);

    console.log("Form Data After Update:", formData);

    setFormData((prevData) => ({
      ...prevData,
      id,
      doctorName,
      clientName,
      startTime: start,
      endTime: end,
      description,
    }));
    setShowModal(true);
    setEditMode(true);

    setClickedEventId(id);
  };

  const closeModal = () => {
    setShowModal(false);
    setEditMode(false);
    setFormData({
      id: 0,
      doctorName: "",
      clientName: "",
      startTime: "",
      endTime: "",
      description: "",
    });
    setClickedEventId(null);
  };

  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    console.log("Clicked Event ID:", clickedEventId);
    console.log("Clicked Event Data:", clickedEventId);
    console.log("Form Data After Update:", formData);

    fetchAppointments();
  }, [clickedEventId, formData]);

  //function to handle input change
  const handleInputChange = (e) => {
    if (e.target.name === "startTime") {
      const newDate = new Date(e.target.value);
      console.log("convert to end date :", newDate);
      const endTime = formatForDisplay(
        new Date(newDate.getTime() + 60 * 60 * 1000)
      );
      console.log("convert to end date :", endTime);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        endTime: endTime,
      });
    } else {
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const fetchAppointments = async () => {
    try {
      const response = await axiosInstance.get(`/api/Schedule`);
      setAppointments(response.data);
      const fetchedAppointments = response.data;

      console.log("Fetched Appointments:", fetchedAppointments);
    } catch (error) {
      console.error("Error fetching appointments:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      let updatedFormData = formData;
      if (editMode) {
        console.log("Updating appointment with ID:", clickedEventId);

        console.log("Updated Appointment Data:", formData);
        updatedFormData = updateStartAndEndTime(formData);
        await axiosInstance.put(
          `/api/Schedule/${clickedEventId}`,
          updatedFormData
        );
      } else {
        await axiosInstance.post("/api/Schedule", formData);
      }

      fetchAppointments();

      console.log("Appointment Data:", formData);
      closeModal();
    } catch (error) {
      console.error("Error saving appointment:", error);
    }
  };

  return (
    <div className={styles.calendar}>
      <FullCalendar
        select={handleDateSelect}
        eventClick={handleEventClick}
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="dayGridMonth"
        headerToolbar={{
          left: "prev,next today",
          center: "title",
          right: "dayGridMonth,timeGridWeek,timeGridDay",
        }}
        editable={true}
        selectable={true}
        selectMirror={true}
        dayMaxEvents={true}
        events={appointments.map((appointment) => ({
          id: appointment.id.toString(),
          title: appointment.doctorName,
          start: new Date(appointment.startTime),
          end: appointment.endTime,
          description: appointment.description,
          clientName: appointment.clientName,
        }))}
      />
      {console.log(appointments)}
      <Modal
        isOpen={showModal}
        onRequestClose={closeModal}
        contentLabel="Add Appointment"
        ariaHideApp={false} // Prevents the modal from closing when the background is clicked
        overlayClassName={showModal ? styles.modalOverlay : ""}
      >
       
          <h2>{editMode ? "Edit Appointment" : "Add Appointment"}</h2>
          <form onSubmit={handleFormSubmit}>
            <label>
              Therapist Name:
              <select
                name="doctorName"
                value={formData.doctorName}
                onChange={handleInputChange}
                style={{
                  width: "100%", // Adjust width to match the input field
                  padding: "8px", // Adjust padding to match the input field
                  margin: "0 0 15px", // Provide some bottom margin for spacing, adjust as necessary
                  borderRadius: "4px", // Match the border radius of the input field
                  border: "1px solid #ccc", // Match the border of the input field
                }}
              >
                <option value="">Select a Therapist</option>
                {therapistNames.map((name, index) => (
                  <option key={index} value={name}>
                    {name}
                  </option>
                ))}
              </select>
            </label>
            <label>
              Client Name:
              <input
                type="text"
                name="clientName"
                value={formData.clientName}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Start Time:
              <input
                type="datetime-local"
                name="startTime"
                value={formData.startTime}
                onChange={handleInputChange}
              />
            </label>
            <label>
              End Time:
              <input
                type="datetime-local"
                name="endTime"
                value={formData.endTime}
                onChange={handleInputChange}
              />
            </label>
            <label>
              Description:
              <textarea
              style={{resize:'none'}}
                name="description"
                value={formData.description}
                onChange={handleInputChange}
              />
            </label>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <button type="submit">
                {editMode ? "Update Appointment" : "Save Appointment"}
              </button>
              <button type="button" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </form>
      </Modal>
    </div>
  );
};

export default CalendarComponent;
